import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"   viewBox="0 0 300.000000 300.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,300.000000) scale(0.100000,-0.100000)">

<path d="M236 2835 l-86 -85 35 -31 c28 -25 33 -34 24 -45 -7 -9 -8 -14 -1
-14 19 0 23 32 6 45 -11 7 -15 19 -11 29 6 14 3 16 -13 11 -25 -8 -26 11 -1
24 24 13 44 -8 35 -35 -6 -16 -3 -15 19 5 27 25 39 53 14 32 -19 -15 -30 -1
-14 18 11 14 10 14 -6 2 -9 -8 -21 -11 -25 -7 -4 4 2 14 12 22 17 12 22 12 40
-4 21 -17 21 -17 2 5 -15 19 -16 25 -6 38 17 21 31 19 59 -10 l23 -25 -22 0
c-11 0 -18 -4 -15 -10 3 -5 -3 -20 -14 -32 -28 -29 -27 -42 2 -26 12 7 26 11
30 10 5 -1 5 2 2 9 -4 6 2 19 14 30 l21 20 -30 25 c-34 29 -37 40 -13 66 9 10
14 18 11 18 -3 0 -45 -38 -92 -85z"/>
<path d="M1998 2898 c-23 -28 -23 -30 8 -59 25 -23 26 -23 15 -2 -6 12 -16 25
-22 28 -7 5 -2 16 12 31 12 13 18 24 13 24 -5 0 -17 -10 -26 -22z"/>
<path d="M1085 2879 c4 -5 18 -9 32 -9 15 0 37 -10 50 -22 21 -20 23 -20 23
-3 0 10 -3 15 -7 12 -3 -4 -11 -1 -17 7 -12 15 -90 30 -81 15z"/>
<path d="M2762 2885 c11 -11 88 -24 88 -14 0 5 -6 9 -14 9 -7 0 -29 3 -47 6
-19 4 -31 3 -27 -1z"/>
<path d="M1040 2871 c0 -6 -8 -15 -17 -18 -22 -9 -37 -24 -41 -43 -2 -8 14 2
34 22 21 21 35 40 31 44 -4 4 -7 2 -7 -5z"/>
<path d="M1951 2866 c8 -9 -33 -54 -43 -49 -5 2 -8 -1 -8 -7 0 -6 -9 -20 -20
-32 -11 -12 -20 -18 -20 -13 0 16 -29 -30 -30 -46 0 -9 11 -21 25 -27 31 -14
33 0 3 21 l-23 15 25 20 c14 12 31 21 39 22 8 0 11 3 8 7 -9 9 12 33 29 33 18
0 54 -31 54 -47 0 -6 5 -15 12 -19 7 -4 8 -3 4 5 -8 12 27 53 54 64 12 4 12 6
-2 6 -9 1 -19 -3 -22 -8 -3 -5 -16 -7 -29 -3 -20 5 -21 4 -8 -11 21 -27 -3
-20 -29 8 -18 19 -21 27 -11 39 9 11 9 16 -1 22 -7 4 -10 4 -7 0z"/>
<path d="M1030 2819 c-19 -21 -35 -43 -34 -49 3 -26 5 -29 24 -27 11 1 18 7
16 13 -7 18 23 54 44 54 26 0 52 -38 40 -60 -12 -23 -37 -32 -58 -21 -22 11
-44 3 -35 -12 4 -7 -1 -6 -10 2 -10 8 -21 11 -24 7 -9 -9 45 -56 64 -56 7 0
13 -5 13 -11 0 -5 5 -7 10 -4 6 3 10 16 10 28 0 12 -4 18 -8 12 -11 -15 -34
-17 -30 -3 3 7 15 15 29 16 13 2 37 17 52 32 16 16 34 27 42 24 7 -3 16 0 20
6 8 13 -34 62 -46 54 -16 -10 -9 -23 9 -18 15 5 15 4 -1 -6 -15 -10 -21 -8
-32 6 -11 16 -10 20 4 31 14 11 13 12 -11 13 -14 0 -32 2 -40 4 -7 2 -29 -13
-48 -35z"/>
<path d="M2736 2831 c-15 -15 -26 -34 -24 -42 2 -9 -4 -13 -14 -11 -29 5 -11
-57 28 -97 31 -31 62 -35 50 -6 -6 16 64 85 86 85 61 0 3 79 -68 93 -26 5 -36
1 -58 -22z m72 -33 c33 -33 -16 -91 -57 -68 -39 20 -21 80 24 80 12 0 26 -5
33 -12z"/>
<path d="M2665 2800 c-9 -18 -14 -34 -11 -37 8 -9 -32 -46 -42 -39 -6 3 -8 -2
-5 -13 4 -16 0 -20 -18 -18 -13 0 -28 6 -32 12 -5 6 -6 2 -3 -9 5 -13 2 -17
-8 -13 -17 7 -30 -19 -15 -29 6 -4 4 -13 -7 -25 -13 -14 -20 -16 -31 -8 -7 6
-13 18 -13 26 0 20 -16 8 -23 -17 -4 -16 3 -24 34 -39 21 -11 39 -15 39 -11 0
5 -5 12 -12 16 -7 5 -5 11 7 21 24 17 36 17 29 -1 -6 -16 38 -47 63 -44 35 3
51 -2 57 -18 4 -12 1 -14 -14 -9 -11 4 -20 2 -20 -4 0 -12 28 -19 53 -13 18 5
20 34 2 32 -14 -2 -24 33 -13 47 6 7 8 19 5 27 -4 10 -1 13 7 10 10 -4 12 2 9
20 -5 25 -6 25 -34 10 -16 -8 -29 -19 -29 -25 0 -6 -4 -7 -10 -4 -5 3 -10 1
-10 -4 0 -6 14 -11 31 -11 20 0 29 -4 26 -12 -2 -7 -20 -13 -39 -13 -20 0 -35
2 -33 5 2 3 -3 11 -11 18 -9 8 -14 9 -14 2 0 -7 -5 -6 -14 2 -12 11 -11 14 7
23 26 12 57 41 57 54 0 5 7 11 15 15 19 7 19 1 -1 -29 -15 -24 -15 -24 9 -12
22 10 24 16 20 60 -2 29 1 54 8 63 6 8 9 17 6 20 -3 3 -13 -8 -22 -26z"/>
<path d="M1220 2781 c0 -12 -4 -21 -10 -21 -5 0 -10 -7 -10 -15 0 -8 -6 -15
-13 -15 -16 0 -101 -90 -92 -98 3 -4 9 -2 13 4 16 23 43 24 52 1 5 -13 9 -16
9 -6 1 8 -3 19 -9 25 -6 6 1 22 22 44 21 24 33 31 36 21 2 -7 -1 -17 -7 -20
-6 -4 4 -7 23 -7 33 1 42 16 9 16 -11 0 -13 4 -6 13 13 17 10 70 -5 75 -7 2
-12 -5 -12 -17z"/>
<path d="M1310 2780 c0 -5 5 -10 10 -10 6 0 10 -5 10 -11 0 -6 -9 -8 -20 -4
-11 3 -20 2 -20 -3 0 -5 10 -9 23 -8 12 0 22 7 21 14 -2 27 -4 32 -14 32 -5 0
-10 -4 -10 -10z"/>
<path d="M1926 2778 c-3 -7 -7 -23 -9 -36 -5 -20 -3 -21 10 -11 12 10 16 10
23 0 5 -8 10 -9 14 -3 3 6 0 13 -6 16 -11 5 -11 7 0 12 6 3 9 10 6 15 -3 6
-12 5 -22 -3 -13 -11 -15 -10 -9 5 3 9 4 17 1 17 -2 0 -6 -6 -8 -12z"/>
<path d="M409 2779 c0 -2 -2 -11 -4 -19 -3 -16 40 -21 62 -7 6 4 14 1 16 -5 3
-8 6 -6 6 5 1 19 -25 24 -32 5 -3 -9 -10 -8 -26 6 -11 11 -21 17 -22 15z"/>
<path d="M2845 2700 c-55 -55 -75 -70 -92 -65 -17 4 -20 1 -16 -14 5 -18 6
-18 36 -3 51 27 169 152 144 152 -1 0 -34 -32 -72 -70z"/>
<path d="M963 2730 c5 -44 17 -49 17 -7 0 21 -4 37 -10 37 -5 0 -8 -14 -7 -30z"/>
<path d="M1880 2740 c-13 -9 -10 -15 16 -38 64 -56 74 -63 74 -48 0 8 -8 17
-17 21 -17 6 -17 7 2 21 20 15 20 15 0 12 -11 -2 -25 -7 -32 -11 -14 -9 -33
21 -26 41 6 14 3 15 -17 2z"/>
<path d="M2433 2733 c-20 -7 -15 -19 12 -31 21 -10 25 -9 25 3 0 8 -6 14 -12
13 -7 -2 -12 3 -10 10 1 6 1 12 0 11 -2 0 -9 -3 -15 -6z"/>
<path d="M136 2722 c-3 -5 8 -19 24 -31 17 -12 30 -18 30 -13 0 15 -48 54 -54
44z"/>
<path d="M110 2675 c-12 -15 -5 -55 11 -55 5 0 9 5 9 10 0 6 -4 10 -10 10 -14
0 -13 27 2 36 6 4 16 0 23 -9 7 -9 15 -14 18 -10 9 8 -12 33 -28 33 -7 0 -18
-7 -25 -15z"/>
<path d="M2272 2682 c-13 -2 -21 -9 -19 -15 3 -7 10 -11 16 -9 9 2 9 -3 1 -17
-13 -26 -13 -31 4 -31 7 0 16 -9 19 -20 6 -24 36 -27 33 -4 -1 9 -10 20 -19
26 -19 10 -23 38 -6 38 5 0 7 -5 3 -12 -4 -7 -3 -8 4 -4 7 4 12 2 12 -4 0 -7
7 -10 15 -6 8 3 15 1 15 -4 0 -16 27 -11 34 6 3 9 12 14 20 11 8 -3 16 2 19
15 3 11 10 17 16 13 6 -3 11 -2 11 2 0 5 -16 9 -35 9 -20 0 -29 -3 -22 -8 9
-6 9 -10 -1 -20 -10 -10 -14 -10 -21 0 -9 15 -20 15 -38 1 -7 -7 -13 -6 -17 0
-4 5 2 15 11 21 14 9 15 10 2 7 -9 -3 -20 -2 -25 2 -5 4 -19 5 -32 3z"/>
<path d="M1805 2669 c-9 -14 3 -59 15 -59 6 0 10 7 10 15 0 8 -5 15 -11 15 -6
0 -9 9 -6 20 6 22 2 26 -8 9z"/>
<path d="M433 2644 c-4 -10 -13 -14 -27 -10 -20 5 -20 4 -2 -9 21 -16 36 -11
36 11 0 8 5 14 10 14 6 0 10 -5 10 -11 0 -5 5 -7 10 -4 16 10 11 21 -11 22
-11 1 -23 -5 -26 -13z"/>
<path d="M773 2653 c-7 -2 -9 -11 -6 -19 3 -9 -2 -14 -13 -15 -11 0 -14 -3 -6
-6 13 -5 17 -43 5 -43 -19 1 -32 18 -29 36 3 15 -2 20 -22 22 -20 1 -23 -1
-12 -8 8 -5 11 -12 7 -16 -3 -4 -13 -1 -22 6 -9 7 -18 10 -21 7 -3 -3 4 -10
15 -17 17 -9 19 -15 11 -30 -13 -25 -13 -27 5 -27 8 0 15 9 15 21 0 19 1 19
21 -2 11 -12 24 -20 28 -18 5 3 22 1 40 -4 32 -9 46 5 16 16 -8 4 -15 12 -15
20 0 8 -4 14 -10 14 -5 0 -10 8 -10 17 0 13 3 14 12 5 8 -8 17 -8 31 -1 10 6
27 9 36 6 10 -2 23 4 30 14 9 15 8 19 -4 19 -8 0 -15 -5 -15 -11 0 -6 -8 -8
-20 -4 -10 3 -28 1 -40 -5 -12 -6 -20 -7 -20 -1 0 5 5 11 12 13 7 3 8 7 3 10
-6 3 -15 3 -22 1z"/>
<path d="M944 2636 c-8 -32 13 -63 30 -45 8 8 7 13 -2 16 -9 4 -10 9 -2 18 7
9 6 16 -4 25 -13 10 -16 7 -22 -14z"/>
<path d="M1856 2631 c-17 -19 -15 -23 14 -33 11 -4 16 1 17 13 1 13 -2 17 -10
12 -7 -4 -6 0 2 10 19 23 -2 22 -23 -2z"/>
<path d="M2024 2623 c3 -23 8 -27 39 -28 19 0 33 3 31 7 -3 5 -17 8 -32 8 -21
0 -24 2 -12 10 13 9 13 11 0 20 -24 15 -29 12 -26 -17z"/>
<path d="M2126 2633 c2 -7 -3 -13 -11 -13 -11 0 -10 -5 5 -20 11 -11 24 -20
29 -20 5 0 11 -5 13 -11 4 -11 38 -2 38 10 0 4 -14 15 -30 25 -17 9 -27 22
-24 28 4 6 2 8 -4 4 -5 -3 -12 -3 -15 1 -2 5 -2 2 -1 -4z"/>
<path d="M1976 2624 c-13 -12 -13 -18 -3 -28 12 -14 37 -11 37 5 0 4 -7 6 -16
2 -14 -5 -15 -3 -5 15 14 26 9 28 -13 6z"/>
<path d="M1064 2622 c3 -6 -2 -15 -11 -21 -12 -7 -13 -10 -4 -11 11 0 11 -3 1
-15 -17 -20 -2 -34 28 -26 23 6 23 6 3 27 -13 15 -16 25 -9 32 6 6 6 12 -3 17
-7 5 -10 4 -5 -3z"/>
<path d="M1230 2622 c2 -31 72 -64 86 -40 3 5 16 5 30 2 14 -4 24 -2 24 5 0 9
-70 17 -102 12 -5 0 -8 3 -8 8 0 5 -7 11 -15 15 -8 3 -15 2 -15 -2z"/>
<path d="M2189 2608 c-12 -8 -11 -9 3 -5 21 7 33 -32 12 -40 -21 -8 -17 -23 7
-23 15 0 19 5 15 15 -8 21 11 19 23 -2 7 -12 10 -14 11 -4 0 11 3 11 16 1 12
-11 15 -11 12 1 -2 8 -14 17 -28 20 -14 3 -24 10 -23 17 4 18 -30 32 -48 20z"/>
<path d="M279 2601 c11 -7 11 -9 0 -14 -10 -4 -8 -6 4 -6 10 -1 16 -5 12 -10
-3 -6 3 -15 14 -21 14 -7 22 -7 27 2 4 7 3 8 -4 4 -6 -3 -16 3 -22 14 -6 11
-7 20 -3 20 12 0 -20 20 -32 20 -5 0 -4 -4 4 -9z"/>
<path d="M453 2603 c-26 -5 -27 -7 -12 -34 11 -22 31 -17 22 6 -3 10 2 15 16
15 12 0 21 5 21 10 0 10 -8 11 -47 3z"/>
<path d="M920 2597 c0 -15 -3 -16 -22 -6 -17 9 -20 9 -15 -4 3 -8 0 -20 -6
-26 -8 -8 -8 -11 0 -11 6 0 14 6 17 13 5 9 7 9 12 -1 5 -10 10 -8 21 8 11 16
12 25 4 33 -8 8 -11 6 -11 -6z"/>
<path d="M2700 2601 c0 -9 27 -41 36 -41 13 0 1 31 -16 40 -12 6 -20 7 -20 1z"/>
<path d="M540 2587 c0 -28 27 -40 75 -33 72 11 59 27 -32 41 -34 5 -43 4 -43
-8z"/>
<path d="M810 2591 c0 -5 7 -14 16 -22 13 -10 17 -10 25 2 14 23 11 29 -16 29
-14 0 -25 -4 -25 -9z"/>
<path d="M1797 2587 c59 -23 56 -35 -4 -18 -26 8 -35 6 -49 -10 -9 -10 -14
-19 -9 -19 4 0 14 8 21 18 8 9 14 12 14 5 0 -8 17 -11 56 -8 31 2 59 6 61 9 3
3 12 -1 20 -9 8 -8 29 -15 46 -14 18 0 26 3 19 6 -6 2 -10 8 -8 12 3 4 0 13
-6 21 -8 12 -9 11 -4 -4 5 -16 3 -17 -21 -12 -16 4 -36 7 -45 7 -10 0 -18 4
-18 9 0 5 -8 8 -17 8 -10 -1 -34 2 -53 5 l-35 7 32 -13z"/>
<path d="M370 2582 c0 -12 56 -63 62 -57 3 3 -7 19 -22 35 -28 30 -40 37 -40
22z"/>
<path d="M1000 2574 c0 -10 -6 -14 -15 -10 -8 3 -15 1 -15 -4 0 -6 14 -10 30
-10 23 0 30 4 30 20 0 11 -7 20 -15 20 -8 0 -15 -7 -15 -16z"/>
<path d="M490 2557 l-25 -10 24 -5 c13 -2 28 1 33 6 15 15 -4 21 -32 9z"/>
<path d="M1210 2562 c8 -3 18 -9 21 -15 4 -5 14 -5 26 2 11 5 23 7 27 4 3 -3
6 -2 6 3 0 5 -21 10 -47 11 -27 0 -41 -2 -33 -5z"/>
<path d="M2099 2555 c-1 -3 -2 -9 -3 -14 0 -5 -4 -12 -7 -16 -11 -10 -202 -6
-213 5 -14 14 -63 13 -72 -1 -4 -7 1 -16 10 -21 9 -5 14 -13 11 -19 -4 -5 -1
-9 5 -9 7 0 10 7 6 15 -3 9 3 19 14 25 18 10 20 7 20 -20 0 -28 2 -30 38 -30
33 1 35 2 20 14 -10 7 -15 16 -11 19 9 10 35 9 29 -1 -10 -16 11 -29 57 -36
46 -7 48 -6 43 12 -4 11 -2 23 3 27 6 3 13 -6 17 -20 4 -18 13 -25 29 -25 19
0 22 4 17 22 -3 14 -2 19 6 14 7 -4 12 -16 12 -26 0 -13 7 -18 20 -17 21 1 29
17 8 17 -6 0 -9 3 -6 6 3 3 14 1 25 -5 10 -5 26 -12 36 -15 9 -3 17 -10 17
-16 0 -13 -23 -13 -36 0 -7 7 -11 6 -11 -4 0 -8 4 -13 9 -10 4 3 8 1 8 -4 0
-5 11 -15 25 -22 13 -7 22 -17 19 -22 -3 -4 2 -8 10 -8 30 0 16 -16 -16 -18
-18 -2 -35 -6 -38 -11 -3 -5 -14 -5 -25 0 -11 4 -15 8 -10 9 35 5 49 10 59 20
6 6 7 11 1 10 -32 -7 -51 3 -63 30 -8 17 -17 28 -22 25 -14 -9 -18 -45 -7 -58
13 -13 13 -14 17 -205 2 -73 1 -134 -2 -137 -8 -8 4 -65 14 -65 5 0 6 6 2 13
-5 9 -4 9 5 1 12 -11 5 -44 -10 -44 -5 0 -9 -6 -9 -13 0 -7 -9 -18 -20 -25
-11 -7 -20 -19 -20 -27 0 -8 -8 -15 -17 -15 -13 0 -14 -3 -6 -11 16 -16 44 -4
50 21 3 11 10 20 17 19 28 -3 47 2 41 11 -4 6 -11 8 -16 5 -5 -4 -9 2 -9 12 0
11 12 23 29 30 24 9 29 16 27 37 -3 22 0 25 15 21 13 -4 19 -1 19 8 0 10 7 14
23 11 12 -2 16 -2 10 2 -17 7 -18 211 -2 286 20 95 103 157 210 158 l57 0 4
36 c3 26 0 38 -13 45 -23 12 -29 11 -29 -1 0 -5 9 -10 21 -10 11 0 17 -4 14
-10 -3 -5 -20 -10 -37 -10 -17 0 -37 -8 -45 -17 -14 -17 -14 -17 -9 0 7 23 1
22 -28 -8 -28 -28 -43 -32 -43 -11 0 7 7 13 15 12 8 0 17 5 19 12 3 7 -5 12
-20 12 -19 0 -27 6 -30 23 -4 21 -4 21 -6 1 0 -12 -8 -27 -16 -34 -8 -7 -15
-19 -15 -28 0 -8 -3 -12 -7 -9 -8 9 -45 -51 -38 -63 3 -4 -2 -11 -11 -14 -23
-9 -24 -8 -24 17 0 27 -28 57 -53 57 -9 0 -16 3 -15 8 5 24 -17 42 -52 42 -33
0 -37 3 -33 20 3 11 3 20 -1 20 -3 0 -7 -2 -7 -5z m161 -225 c0 -5 -7 -7 -15
-4 -18 7 -20 -7 -2 -25 10 -11 7 -12 -15 -7 -25 6 -26 4 -23 -25 6 -42 14 -48
31 -25 7 11 16 17 19 14 8 -7 -5 -38 -16 -38 -5 0 -9 -7 -9 -16 0 -8 -4 -12
-10 -9 -13 8 -13 -1 2 -28 6 -12 11 -48 10 -80 -1 -36 2 -56 8 -52 6 3 10 1
10 -5 0 -6 -8 -10 -17 -8 -11 2 -19 -6 -23 -21 -5 -23 -6 -23 -23 -7 -21 21
-21 27 -1 52 14 18 15 18 4 -3 -7 -16 -7 -23 0 -23 5 0 10 7 10 16 0 9 5 12
13 8 6 -4 1 4 -11 19 l-24 25 21 11 c30 17 27 33 -5 21 -38 -15 -40 -12 -10
15 34 32 35 53 0 30 -29 -19 -26 -7 10 34 12 13 17 22 11 19 -5 -3 -18 5 -28
17 -16 22 -16 25 -1 33 11 6 15 17 11 32 -4 16 -2 20 9 16 8 -3 14 0 14 7 0 7
11 14 25 15 14 1 25 -3 25 -8z"/>
<path d="M2210 2520 c-12 -8 -10 -14 15 -40 l29 -30 23 27 c28 35 28 43 3 43
-11 0 -20 -6 -20 -13 0 -7 -10 -13 -21 -14 -17 -2 -19 1 -10 10 23 23 10 35
-19 17z"/>
<path d="M2587 2524 c-11 -12 13 -44 33 -44 26 0 25 8 -2 31 -24 22 -23 21
-31 13z"/>
<path d="M723 2513 c9 -3 15 -9 12 -14 -3 -5 1 -6 11 -2 13 5 15 2 9 -18 -7
-24 -7 -24 22 4 33 31 55 37 29 8 -16 -18 -16 -19 1 -17 10 0 23 8 30 16 9 12
10 9 7 -15 -2 -17 -1 -24 2 -17 3 6 10 10 15 6 5 -3 9 -1 9 4 0 5 11 14 23 19
22 10 24 9 25 -23 1 -32 0 -34 -19 -23 -17 9 -20 8 -17 -3 3 -7 16 -13 29 -13
20 0 23 4 21 24 -3 20 0 23 17 18 16 -4 21 0 21 16 0 16 3 18 12 9 7 -7 17
-12 23 -12 5 0 3 5 -5 11 -13 9 -13 10 0 6 8 -2 14 -10 13 -16 -2 -6 2 -12 7
-12 6 -1 15 -3 20 -4 6 -1 15 -3 20 -4 6 0 10 -6 10 -12 0 -5 -5 -7 -10 -4 -6
4 -18 1 -27 -7 -15 -11 -16 -11 -10 6 7 17 6 17 -10 5 -15 -12 -16 -12 -10 4
3 9 2 17 -3 17 -6 0 -10 -6 -10 -14 0 -9 -7 -13 -18 -11 -11 2 -19 -3 -19 -11
0 -8 4 -14 9 -14 5 0 7 -4 3 -9 -3 -5 -1 -12 5 -16 5 -3 10 -2 10 3 0 14 32
24 42 14 5 -5 -3 -14 -16 -22 -14 -7 -27 -19 -30 -27 -4 -10 4 -7 23 6 26 19
29 19 26 4 -2 -10 -4 -25 -4 -33 -1 -13 -2 -13 -12 0 -9 12 -10 11 -6 -7 3
-13 2 -23 -3 -23 -14 0 -21 -33 -8 -41 7 -5 13 -24 14 -44 1 -27 -3 -35 -16
-35 -10 0 -20 -9 -23 -21 -5 -19 -4 -19 17 -5 22 14 23 13 18 -10 -2 -13 -10
-24 -18 -24 -12 0 -29 18 -29 32 0 3 -3 10 -7 14 -7 7 -12 -9 -9 -28 0 -5 -13
-8 -29 -8 -28 0 -30 -2 -19 -21 9 -17 8 -20 -3 -13 -7 4 -21 19 -32 32 -10 13
-25 21 -32 18 -9 -3 -14 1 -14 13 0 10 -9 26 -20 36 -20 18 -20 18 0 35 20 18
28 46 10 35 -5 -3 -10 -1 -10 5 0 7 -6 10 -12 7 -7 -2 -12 -10 -10 -16 1 -7
-2 -10 -8 -6 -6 4 -10 -12 -10 -43 0 -47 1 -49 44 -70 62 -32 71 -45 51 -76
-22 -33 -35 -33 -36 -1 -2 45 -14 69 -30 60 -5 -4 -9 2 -9 12 -1 14 -3 13 -14
-7 -10 -16 -11 -32 -5 -48 5 -13 7 -27 4 -32 -3 -5 0 -12 6 -15 6 -4 14 -17
19 -29 8 -19 9 -19 9 7 1 31 15 35 30 10 8 -13 10 -14 11 -4 0 8 3 11 7 8 3
-4 11 1 18 9 11 15 14 15 33 -2 21 -17 21 -19 5 -26 -15 -5 -14 -7 5 -7 23 -1
24 2 20 58 -1 33 -1 58 1 56 2 -3 11 0 20 4 17 9 37 -5 35 -24 -4 -44 -18 -84
-28 -79 -6 3 -3 -1 8 -10 10 -8 16 -22 13 -31 -4 -8 -2 -15 3 -15 6 0 10 -5
10 -11 0 -7 -9 -9 -21 -6 -15 4 -19 2 -15 -9 3 -8 -1 -14 -9 -14 -8 0 -15 5
-15 11 0 5 -4 7 -10 4 -13 -8 -13 -25 0 -25 6 0 20 -9 32 -20 16 -15 19 -22 9
-33 -9 -11 -8 -17 5 -28 13 -10 17 -10 24 0 6 11 12 11 26 1 16 -10 16 -11 2
-6 -13 4 -18 0 -18 -14 0 -11 7 -20 15 -20 24 0 6 -27 -23 -36 -15 -4 -30 -7
-34 -6 -4 1 -4 -6 -1 -15 5 -12 3 -14 -8 -8 -11 7 -12 6 -2 -6 7 -8 10 -26 7
-40 -3 -13 -2 -20 3 -14 4 6 18 16 31 23 23 13 26 7 25 -35 -1 -7 5 -13 12
-13 7 0 13 12 13 26 0 18 -3 22 -9 13 -7 -11 -9 -10 -9 3 0 9 6 18 13 21 6 2
9 8 5 12 -4 4 -19 0 -32 -9 -32 -21 -36 -20 -36 5 0 15 4 19 19 14 11 -3 22
-1 26 4 4 7 -1 9 -12 5 -16 -5 -16 -4 1 10 10 8 23 13 28 10 11 -7 48 5 48 16
0 4 -7 11 -16 14 -8 3 -12 2 -9 -3 3 -5 1 -12 -5 -16 -6 -4 -10 16 -10 54 0
42 4 61 13 61 9 0 9 2 -1 9 -21 13 -17 367 4 443 25 88 90 128 209 128 61 0
62 0 57 25 l-4 26 -129 -2 c-80 -1 -129 -5 -129 -11 0 -7 -5 -6 -13 1 -9 7
-69 10 -173 10 -87 -1 -151 -4 -141 -6z m387 -29 c0 -8 7 -11 18 -8 13 4 13 3
-2 -7 -14 -9 -21 -9 -24 -1 -2 6 -9 9 -17 6 -10 -4 -12 0 -8 15 5 22 33 18 33
-5z m-107 -489 c-1 -40 -6 -59 -14 -57 -7 2 -9 -4 -5 -13 3 -9 6 -21 6 -26 0
-5 7 -9 15 -9 8 0 15 -4 15 -9 0 -17 -43 -7 -48 11 -2 10 -8 15 -13 12 -17
-10 -9 15 10 33 19 18 22 48 7 72 -5 8 -3 17 5 22 10 6 11 9 2 9 -7 0 -13 4
-13 8 0 4 8 8 18 8 14 0 17 -9 15 -61z"/>
<path d="M610 2500 c-14 -4 -20 -9 -15 -9 6 -1 3 -6 -5 -11 -13 -8 -13 -10 2
-10 9 0 18 -5 20 -11 2 -7 12 -7 33 1 16 6 26 8 22 4 -4 -4 3 -18 15 -31 24
-26 13 -31 -18 -8 -28 21 -37 18 -20 -7 20 -28 3 -56 -22 -36 -13 11 -15 10
-8 -6 7 -20 7 -20 -15 0 l-22 19 8 -25 c9 -30 5 -26 31 -34 17 -5 24 -1 33 22
12 28 57 59 69 47 4 -3 0 -15 -7 -27 -11 -18 -11 -20 3 -15 12 5 17 -2 22 -33
7 -42 13 -47 32 -28 17 17 15 35 -7 57 -11 11 -17 29 -16 45 2 14 -1 24 -6 20
-5 -3 -9 0 -9 5 0 6 -5 11 -11 11 -5 0 -7 -4 -4 -10 3 -5 2 -10 -3 -10 -5 0
-13 19 -18 43 -8 35 -13 42 -34 44 -14 1 -36 -2 -50 -7z"/>
<path d="M2739 2494 c-8 -10 -22 -14 -40 -10 -16 3 -34 -1 -45 -10 -18 -14
-18 -15 3 -5 15 8 24 8 29 0 3 -6 16 -8 27 -5 12 3 25 -1 31 -10 9 -11 11 -7
11 21 1 41 1 40 -16 19z"/>
<path d="M492 2475 c-1 -21 26 -58 29 -39 6 42 4 53 -10 57 -12 3 -17 -3 -19
-18z"/>
<path d="M540 2440 c-6 -11 -9 -20 -8 -21 27 -7 33 -4 34 17 2 29 -11 31 -26
4z"/>
<path d="M778 2435 c-20 -27 -23 -39 -8 -30 14 9 12 -8 -2 -24 -11 -11 -9 -13
10 -8 18 4 22 2 18 -9 -3 -8 1 -14 10 -14 11 0 13 6 8 23 -4 12 -7 28 -7 35 0
6 -4 12 -9 12 -17 0 -7 21 15 30 20 8 20 9 2 9 -11 0 -28 -10 -37 -24z"/>
<path d="M1525 2448 c-5 -7 -5 -10 0 -6 4 4 14 1 22 -7 17 -17 43 -20 43 -5 0
6 -8 10 -18 10 -10 0 -23 5 -29 11 -8 8 -13 7 -18 -3z"/>
<path d="M2595 2449 c-11 -4 -14 -8 -7 -8 10 -1 10 -5 0 -21 -7 -11 -16 -20
-21 -20 -4 0 -6 4 -2 9 3 5 1 12 -5 16 -6 4 -10 -7 -10 -28 0 -23 -5 -34 -15
-34 -8 0 -15 4 -15 9 0 4 -5 8 -10 8 -6 0 -5 -8 2 -19 7 -11 16 -17 22 -14 5
3 6 1 2 -5 -12 -19 14 -24 32 -6 16 17 16 18 0 11 -10 -3 -18 -3 -18 0 0 14
53 53 71 53 10 0 21 9 24 21 11 32 -3 35 -26 5 -12 -15 -24 -25 -27 -21 -3 3
3 11 13 18 23 18 16 37 -10 26z"/>
<path d="M456 2434 c-3 -8 -10 -12 -16 -9 -5 3 -7 0 -4 -8 3 -9 13 -13 25 -10
22 6 26 -12 4 -21 -8 -3 -15 -15 -15 -26 0 -21 -26 -28 -32 -9 -2 6 -10 -2
-17 -17 -10 -23 -10 -27 0 -16 19 18 39 15 33 -5 -5 -17 -5 -17 6 0 12 19 40
23 40 6 0 -6 -9 -23 -21 -37 -19 -24 -19 -27 -4 -30 21 -5 26 0 28 27 1 11 7
21 14 21 7 0 13 5 13 10 0 6 5 10 10 10 6 0 10 -7 10 -15 0 -8 5 -15 10 -15 6
0 10 7 10 15 0 8 7 15 15 15 8 0 15 7 15 16 0 12 -6 15 -25 10 -18 -5 -25 -2
-25 8 0 8 6 16 13 19 7 2 -3 18 -27 41 -45 40 -51 43 -60 20z m51 -40 c-3 -4
-2 -15 4 -25 10 -20 -4 -28 -16 -9 -3 5 -13 6 -23 3 -10 -4 -7 2 8 15 23 20
42 31 27 16z"/>
<path d="M1363 2429 c26 -20 27 -22 10 -35 -16 -12 -15 -13 15 -14 22 0 32 5
32 15 0 14 12 20 33 16 5 -1 6 3 2 9 -4 7 -12 7 -26 0 -15 -8 -24 -6 -41 10
-12 11 -29 20 -37 20 -11 -1 -7 -8 12 -21z"/>
<path d="M1468 2435 c18 -8 36 -15 40 -15 4 -1 15 -8 24 -17 9 -8 22 -12 29
-7 13 8 -81 54 -110 54 -9 -1 -1 -7 17 -15z"/>
<path d="M2007 2418 c3 -18 6 -34 7 -34 1 -1 8 6 17 15 16 15 15 17 -7 34
l-24 18 7 -33z"/>
<path d="M2080 2442 c0 -5 9 -17 20 -27 16 -14 20 -15 20 -4 0 16 -19 39 -32
39 -4 0 -8 -4 -8 -8z"/>
<path d="M2520 2440 c0 -5 -5 -9 -11 -7 -6 1 -14 -4 -17 -12 -3 -9 0 -11 8 -6
10 6 12 4 7 -8 -4 -11 -1 -17 7 -17 8 0 16 7 20 15 3 8 1 15 -4 15 -5 0 -7 7
-4 15 4 8 3 15 0 15 -3 0 -6 -4 -6 -10z"/>
<path d="M838 2433 c-24 -6 -23 -23 2 -23 13 0 18 -5 14 -15 -8 -19 3 -19 21
-1 12 12 12 16 -3 28 -9 8 -25 13 -34 11z"/>
<path d="M1305 2430 c-4 -6 6 -15 20 -20 32 -12 35 -12 35 -2 0 14 -48 33 -55
22z"/>
<path d="M1881 2431 c-10 -6 -9 -10 3 -14 9 -4 16 -14 16 -23 0 -14 2 -14 16
-3 14 11 19 9 34 -11 10 -14 16 -29 13 -34 -11 -16 -11 -56 -1 -56 5 0 8 10 6
22 -2 13 2 23 9 23 7 0 18 6 24 13 10 10 9 12 -6 12 -15 0 -17 2 -6 9 17 11 4
34 -15 27 -7 -3 -16 -1 -19 4 -3 5 3 11 12 13 17 4 17 5 0 6 -10 0 -16 4 -14
9 7 10 -56 12 -72 3z"/>
<path d="M889 2379 c-7 -14 -17 -19 -30 -16 -10 3 -17 1 -14 -3 3 -5 -3 -13
-12 -19 -10 -5 -15 -13 -11 -17 4 -4 14 -1 22 5 13 11 15 8 13 -16 -1 -15 -6
-31 -10 -34 -5 -3 8 -19 27 -34 40 -32 43 -40 21 -49 -25 -9 -45 5 -45 30 0
22 -2 23 -20 14 -15 -8 -19 -17 -14 -32 8 -27 54 -77 54 -60 0 7 13 12 30 12
18 0 29 5 27 12 -1 6 9 17 23 25 15 7 19 13 10 13 -13 1 -13 2 1 13 11 8 14
19 9 32 -4 11 -3 30 1 43 11 29 11 45 1 38 -5 -3 -17 9 -27 26 -10 17 -13 28
-6 24 6 -4 11 -2 11 3 0 20 -49 12 -61 -10z m64 -47 c16 -14 16 -17 2 -39 -9
-14 -11 -23 -5 -23 7 0 8 -5 3 -12 -8 -14 -10 -19 -12 -44 0 -8 -5 -13 -9 -10
-5 3 -8 15 -7 28 1 13 -2 26 -8 29 -5 4 -5 15 2 28 8 15 9 27 2 39 -9 14 -10
14 -11 2 0 -25 -40 -23 -40 2 0 14 5 18 15 15 8 -4 17 -2 20 4 7 10 20 5 48
-19z"/>
<path d="M1263 2384 c-32 -14 -48 -32 -18 -20 10 4 15 -1 15 -14 0 -22 16 -27
23 -7 4 9 8 9 15 -2 7 -9 12 -10 16 -2 4 6 16 11 27 11 29 0 18 22 -17 36 -23
9 -36 8 -61 -2z"/>
<path d="M2075 2390 c3 -5 1 -10 -4 -10 -6 0 -11 -8 -12 -17 0 -13 -3 -11 -11
7 -10 22 -12 23 -16 6 -2 -11 3 -25 12 -31 9 -7 13 -15 9 -18 -12 -13 8 -7 32
9 27 18 34 37 11 28 -11 -4 -13 0 -9 15 3 13 1 21 -6 21 -7 0 -9 -4 -6 -10z"/>
<path d="M364 2354 c3 -7 -4 -21 -15 -33 -15 -16 -16 -21 -5 -21 8 0 17 -8 19
-17 3 -12 5 -10 6 7 1 14 7 37 15 52 13 25 13 27 -5 25 -11 -1 -17 -7 -15 -13z"/>
<path d="M1216 2334 c-10 -27 -7 -36 13 -29 13 4 23 0 30 -12 5 -10 12 -15 16
-11 3 3 -5 17 -19 31 -14 14 -22 28 -19 31 4 3 2 6 -4 6 -6 0 -14 -7 -17 -16z"/>
<path d="M2466 2322 c-10 -11 -16 -12 -16 -4 0 7 -9 12 -19 12 -16 0 -21 -9
-26 -57 -3 -32 -8 -71 -10 -88 l-4 -30 29 35 c33 38 37 50 16 50 -8 0 -14 8
-13 19 0 11 6 17 14 14 7 -3 13 0 13 6 0 6 9 11 20 11 11 0 20 6 20 14 0 8 8
16 18 18 14 3 13 5 -5 10 -13 3 -27 -1 -37 -10z"/>
<path d="M1104 2305 c-16 -12 -17 -14 -2 -15 23 0 48 11 48 21 0 14 -24 11
-46 -6z"/>
<path d="M1990 2289 c0 -12 3 -19 7 -16 3 4 12 2 20 -5 7 -6 17 -8 20 -4 4 4
2 11 -6 15 -7 5 -12 12 -11 17 0 5 -6 10 -14 12 -11 2 -16 -4 -16 -19z"/>
<path d="M385 2281 c-8 -25 10 -52 34 -50 13 1 12 2 -2 6 -12 3 -17 11 -14 23
7 28 -9 47 -18 21z"/>
<path d="M1460 2275 c-21 -23 -22 -25 -5 -42 29 -28 51 -25 24 4 -23 24 -24
25 -5 39 11 7 17 16 14 19 -2 3 -15 -6 -28 -20z"/>
<path d="M550 2280 c-8 -5 -11 -10 -6 -10 5 0 5 -6 0 -13 -5 -7 -2 -5 7 5 16
16 18 16 30 1 11 -13 11 -15 1 -9 -9 5 -15 1 -19 -13 -3 -11 -8 -19 -12 -16
-4 2 -19 -9 -34 -25 -24 -25 -25 -29 -9 -36 9 -4 23 -6 30 -5 10 2 11 -2 3
-18 -6 -11 -11 -33 -11 -50 0 -16 -5 -33 -10 -36 -8 -5 -7 -11 1 -21 9 -11 7
-14 -10 -14 -12 0 -20 4 -17 8 3 5 -2 9 -10 9 -8 0 -13 -6 -10 -13 3 -7 -2
-15 -9 -18 -11 -4 -12 -11 -4 -36 14 -39 30 -39 22 0 -5 25 -3 29 11 24 15 -6
21 -22 17 -51 -3 -18 16 -16 23 2 3 8 1 15 -4 15 -6 0 -10 5 -10 11 0 5 3 8 8
5 4 -2 8 17 9 42 3 74 5 82 22 82 12 0 12 3 3 12 -17 17 -15 41 3 34 8 -3 15
-1 15 4 0 6 -6 10 -14 10 -22 0 -26 13 -10 35 12 16 18 17 32 8 27 -16 67 16
57 45 -3 12 -13 22 -21 22 -18 0 -18 -18 0 -22 12 -2 12 -6 3 -19 -9 -12 -18
-14 -37 -7 -22 8 -23 10 -7 19 21 12 22 35 1 43 -19 7 -16 8 -34 -4z"/>
<path d="M2099 2269 c-1 -17 -3 -19 -6 -7 -4 14 -7 15 -16 5 -14 -14 -19 -187
-6 -187 5 0 9 8 9 18 0 14 2 15 10 2 5 -8 12 -11 16 -7 8 8 8 186 0 194 -3 4
-7 -5 -7 -18z"/>
<path d="M510 2274 c-20 -6 -41 -53 -32 -71 7 -11 12 -9 28 8 10 12 13 19 7
15 -19 -11 -16 14 4 36 9 10 14 17 12 17 -2 -1 -11 -3 -19 -5z"/>
<path d="M2515 2272 c-13 -13 -3 -22 20 -19 35 4 40 14 11 19 -14 3 -28 3 -31
0z"/>
<path d="M2728 2258 c3 -14 1 -19 -6 -14 -7 4 -12 1 -12 -9 0 -11 -5 -15 -14
-12 -23 9 -26 -18 -4 -32 12 -7 17 -18 14 -28 -5 -13 -4 -14 9 -3 9 7 15 8 15
2 0 -6 8 -13 18 -15 13 -4 11 -5 -5 -6 -13 0 -23 -7 -23 -15 0 -7 -8 -20 -17
-27 -15 -12 -16 -12 -10 4 4 11 3 16 -3 12 -6 -3 -10 -2 -10 4 0 5 6 12 13 14
9 4 9 6 0 6 -7 1 -10 7 -7 14 3 8 -2 14 -10 14 -10 0 -13 -5 -7 -15 5 -9 0
-23 -15 -40 -27 -28 -30 -45 -11 -60 19 -16 56 -11 49 7 -3 9 -1 12 7 7 6 -4
11 -4 10 1 -3 20 2 25 16 13 12 -10 13 -15 3 -28 -12 -13 -10 -14 9 -1 12 7
26 9 30 5 4 -5 2 -11 -4 -13 -7 -3 -13 -12 -13 -21 0 -12 5 -10 22 8 15 16 18
25 10 28 -15 5 -42 31 -42 40 0 4 4 13 9 21 12 19 31 4 31 -25 0 -14 4 -22 10
-19 13 8 13 45 -1 45 -5 0 -7 5 -3 12 4 6 3 8 -3 5 -5 -4 -16 6 -24 20 -11 21
-11 27 -1 30 15 6 15 42 -1 68 -17 28 -36 30 -29 3z m24 -43 c-6 -31 -20 -42
-28 -21 -8 21 2 46 19 46 10 0 13 -8 9 -25z"/>
<path d="M324 2258 c3 -10 1 -18 -4 -18 -6 0 -10 -18 -10 -41 0 -27 4 -38 11
-33 6 3 15 0 20 -7 7 -11 9 -11 9 3 0 9 -5 18 -12 20 -7 3 -9 12 -4 23 6 18 9
17 30 -10 14 -17 21 -37 18 -49 -4 -17 -2 -19 8 -9 11 10 11 17 1 36 -7 13
-11 27 -10 33 4 22 -2 34 -17 34 -9 0 -23 8 -31 18 -14 16 -14 16 -9 0z"/>
<path d="M669 2260 c-6 -15 15 -43 28 -38 4 2 8 8 9 15 1 6 -4 9 -11 7 -7 -3
-15 3 -17 13 -3 14 -5 15 -9 3z"/>
<path d="M1950 2260 c0 -5 6 -10 13 -10 8 0 7 -4 -3 -10 -8 -5 -11 -10 -6 -10
5 0 -1 -11 -14 -25 -13 -14 -20 -28 -15 -32 4 -5 10 -2 12 5 3 6 16 12 29 12
33 0 66 18 58 31 -3 6 -12 8 -20 5 -9 -3 -19 4 -26 19 -11 25 -28 34 -28 15z"/>
<path d="M1135 2250 c-3 -5 -1 -10 4 -10 6 0 11 -7 11 -15 0 -8 4 -15 10 -15
5 0 7 7 4 15 -8 20 1 19 21 -1 22 -23 29 -12 8 12 -21 22 -49 29 -58 14z"/>
<path d="M1383 2243 c-7 -2 -13 -10 -13 -16 0 -6 -8 -20 -17 -31 -10 -12 -1
-5 20 13 45 42 47 49 10 34z"/>
<path d="M2496 2205 c-9 -13 -16 -27 -16 -31 0 -4 -7 -16 -17 -26 -9 -11 -11
-18 -5 -18 7 0 15 -1 18 -2 3 -2 13 21 23 50 10 28 17 52 16 52 -2 0 -10 -11
-19 -25z"/>
<path d="M2547 2223 c-4 -3 -1 -13 6 -21 10 -13 9 -15 -5 -9 -22 8 -55 -33
-35 -45 6 -4 18 -8 26 -8 11 0 9 5 -5 21 -19 22 -19 22 9 15 25 -6 27 -4 24
18 -3 29 -11 39 -20 29z"/>
<path d="M663 2208 c3 -10 2 -20 -3 -23 -6 -4 -10 -2 -10 4 0 6 -6 8 -14 5
-20 -8 -26 -41 -10 -55 11 -9 14 -8 14 4 0 22 18 31 35 17 13 -10 15 -8 13 14
-1 15 -9 33 -17 39 -12 10 -14 9 -8 -5z"/>
<path d="M420 2189 c0 -5 -6 -7 -12 -3 -9 5 -9 3 1 -9 7 -9 11 -27 8 -39 -6
-22 -6 -23 8 -3 8 11 19 26 25 33 6 7 8 17 5 22 -8 13 -35 13 -35 -1z"/>
<path d="M578 2173 c6 -2 12 -13 12 -23 0 -10 -6 -21 -12 -23 -7 -3 -3 -6 10
-6 30 -1 28 -17 -4 -34 -17 -9 -23 -17 -15 -21 6 -4 11 -2 11 3 0 6 5 11 11
11 5 0 7 -6 3 -12 -6 -10 -4 -10 7 0 17 15 37 16 21 0 -7 -7 -8 -17 -2 -28 7
-12 4 -23 -10 -38 -17 -18 -25 -66 -9 -56 4 2 15 1 25 -3 16 -6 16 -7 -2 -20
-10 -7 -12 -13 -6 -13 7 0 15 0 20 0 13 0 9 49 -4 54 -8 3 -14 10 -14 16 0 14
27 13 33 -2 2 -7 8 -10 12 -6 4 4 -3 17 -16 29 -21 18 -21 20 -4 14 15 -5 17
-3 8 6 -6 6 -9 18 -6 25 4 10 0 12 -13 7 -18 -7 -18 -6 -1 11 21 22 22 36 1
36 -8 0 -13 4 -10 9 3 5 -1 14 -9 21 -8 7 -15 21 -15 31 0 12 -7 19 -17 18
-11 0 -13 -3 -5 -6z"/>
<path d="M2410 2150 c-6 -12 -10 -33 -7 -48 l3 -27 11 25 c6 15 7 26 1 29 -5
1 -1 9 8 15 11 9 13 15 6 20 -6 3 -16 -3 -22 -14z"/>
<path d="M462 2148 c-19 -19 -14 -28 11 -19 12 4 29 6 37 3 9 -2 7 2 -5 12
-24 19 -28 19 -43 4z"/>
<path d="M703 2148 c8 -22 -3 -30 -20 -16 -7 6 -16 8 -20 4 -3 -3 -1 -6 5 -6
7 0 12 -7 12 -15 0 -9 8 -14 23 -13 19 2 23 7 19 25 -2 13 -9 26 -15 30 -6 4
-8 0 -4 -9z"/>
<path d="M1990 2139 c0 -7 7 -17 16 -24 18 -12 29 -71 16 -79 -4 -2 -8 9 -8
26 -2 29 -40 82 -55 73 -8 -6 64 -148 78 -152 13 -5 7 103 -7 138 -12 28 -40
41 -40 18z"/>
<path d="M1793 2107 c-2 -4 -8 -5 -13 -2 -4 3 -10 -2 -14 -10 -9 -23 6 -23 48
1 37 21 37 22 12 21 -16 -1 -30 -5 -33 -10z"/>
<path d="M1927 2108 c-2 -7 1 -27 8 -44 12 -30 12 -31 -9 -28 -13 3 -10 0 7
-7 21 -8 32 -8 40 0 8 8 8 11 -1 11 -6 0 -14 16 -18 35 -7 38 -19 53 -27 33z"/>
<path d="M2439 2115 c-1 -6 -1 -13 0 -17 0 -5 -4 -8 -11 -8 -9 0 -9 -3 0 -12
16 -16 15 -41 0 -35 -7 2 -12 10 -10 16 1 6 -2 11 -8 11 -5 0 -10 -9 -10 -20
0 -15 7 -20 24 -20 39 0 55 39 30 74 -8 11 -15 16 -15 11z"/>
<path d="M1236 2087 c-11 -7 -31 -22 -45 -34 -20 -18 -22 -23 -9 -23 9 0 30
14 47 31 40 40 42 48 7 26z"/>
<path d="M1154 2076 c-14 -11 -16 -17 -7 -26 8 -9 14 -7 23 10 15 28 9 34 -16
16z"/>
<path d="M1812 2074 c-29 -20 -28 -40 4 -69 38 -35 54 -31 19 5 -27 28 -27 30
-11 55 19 29 18 30 -12 9z"/>
<path d="M1842 2068 c-17 -17 -15 -31 8 -51 30 -26 43 -22 39 11 -1 4 -6 6
-11 4 -6 -2 -18 0 -27 3 -14 6 -14 8 2 20 10 7 14 16 9 19 -5 3 -14 0 -20 -6z"/>
<path d="M2105 2053 c-4 -13 -12 -23 -18 -23 -7 0 -7 -4 1 -12 17 -17 35 3 29
33 l-4 24 -8 -22z"/>
<path d="M714 2052 c-19 -12 -23 -50 -6 -57 8 -3 17 -3 20 0 3 3 0 5 -6 5 -7
0 -12 9 -12 21 0 11 4 18 8 15 5 -3 9 1 9 9 0 8 -1 15 -1 15 -1 0 -6 -4 -12
-8z"/>
<path d="M2469 2038 c-10 -18 -8 -24 15 -40 14 -11 35 -40 46 -66 15 -34 23
-43 32 -34 21 21 -33 105 -82 126 -8 3 -5 4 8 2 18 -3 22 -1 18 11 -3 8 -6 17
-6 19 0 13 -21 1 -31 -18z"/>
<path d="M2516 2040 c3 -14 17 -35 30 -48 l24 -23 0 26 c0 16 -6 25 -16 25
-11 0 -14 5 -10 16 4 11 2 15 -8 11 -8 -3 -17 -1 -20 6 -3 7 -3 1 0 -13z"/>
<path d="M2833 2040 c-3 -11 -9 -18 -13 -15 -4 3 -10 -2 -14 -10 -3 -8 -15
-15 -26 -15 -12 0 -19 -3 -17 -7 3 -5 1 -19 -4 -32 -7 -17 -15 -22 -29 -18
-11 3 -18 0 -17 -5 4 -20 -21 -18 -26 2 -4 15 0 20 16 21 12 1 26 1 31 0 13
-3 -18 42 -32 47 -7 2 -10 -3 -6 -12 3 -9 10 -13 15 -10 5 3 9 1 9 -5 0 -13
-45 -8 -64 8 -13 11 -20 8 -45 -20 -17 -18 -28 -35 -25 -38 3 -3 9 0 13 8 6 9
10 8 14 -8 4 -15 10 -18 27 -14 18 5 21 3 15 -8 -7 -11 -6 -12 6 -3 8 6 25 10
39 9 14 -1 28 2 31 7 3 5 13 6 23 2 13 -5 16 -13 11 -33 l-7 -26 26 24 c28 27
36 52 10 30 -15 -12 -16 -11 -9 11 7 22 6 23 -9 11 -24 -20 -30 -8 -6 14 19
17 21 17 45 -7 l25 -26 -32 -36 c-17 -20 -37 -36 -44 -36 -14 0 -27 28 -18 41
3 5 1 9 -5 9 -21 0 -20 -19 4 -44 l24 -26 45 42 45 43 -31 32 c-26 27 -29 35
-18 48 16 19 36 19 44 0 3 -8 11 -15 17 -15 7 0 2 11 -10 24 -14 15 -19 26
-12 31 14 8 14 25 0 25 -6 0 -13 -9 -16 -20z m-165 -85 c9 -19 7 -21 -18 -23
-17 -1 -30 4 -33 13 -8 19 13 31 24 14 7 -11 9 -10 9 5 0 22 3 20 18 -9z"/>
<path d="M1124 2024 c32 -28 43 -26 26 5 -6 12 -20 21 -33 21 -21 -1 -21 -2 7
-26z"/>
<path d="M164 1995 c-27 -25 -44 -45 -39 -45 6 0 28 18 50 41 32 34 44 40 67
35 15 -3 28 -2 28 2 0 4 -13 9 -29 10 -22 2 -40 -9 -77 -43z"/>
<path d="M750 1993 c0 -60 -9 -95 -24 -90 -6 1 -22 -7 -35 -20 -13 -13 -19
-23 -13 -23 7 0 12 -5 12 -11 0 -5 -4 -7 -10 -4 -5 3 -7 0 -4 -9 9 -23 30 -26
47 -6 14 17 14 20 -6 33 l-22 15 25 10 c15 6 27 6 31 0 7 -10 39 0 39 13 0 5
-6 18 -12 29 -11 17 -10 22 2 30 12 7 12 12 2 22 -6 6 -12 20 -12 29 0 10 -4
21 -10 24 -6 4 -10 -12 -10 -42z"/>
<path d="M1760 2027 c0 -5 11 -18 25 -29 21 -17 25 -28 25 -76 0 -34 4 -52 9
-44 4 7 6 25 3 39 -3 14 1 28 8 33 9 6 2 19 -29 47 -22 22 -41 36 -41 30z"/>
<path d="M325 1999 c58 -51 56 -70 -9 -130 -74 -68 -104 -121 -35 -62 14 11
18 11 29 -2 7 -8 20 -15 29 -15 19 1 61 30 42 30 -7 0 -9 6 -6 13 4 6 -2 1
-13 -12 -13 -16 -25 -22 -36 -18 -16 6 -16 9 -2 22 8 8 20 15 26 15 7 0 10 7
6 15 -3 8 -1 15 5 15 6 0 7 5 4 10 -3 6 -2 10 3 10 18 0 33 45 23 70 -5 14
-10 19 -10 12 -1 -10 -5 -10 -16 2 -9 8 -16 19 -16 23 0 8 -38 32 -52 33 -4 0
8 -14 28 -31z"/>
<path d="M184 1969 c-49 -50 -48 -88 1 -138 34 -33 55 -34 55 -2 0 22 75 95
89 87 6 -4 14 0 18 10 9 24 -53 78 -93 81 -24 2 -39 -7 -70 -38z m94 -43 c5
-25 -17 -46 -49 -46 -23 0 -30 6 -35 27 -6 20 -2 31 16 45 20 16 25 17 44 5
12 -8 22 -22 24 -31z"/>
<path d="M1063 2002 c-13 -4 -19 -12 -15 -17 5 -7 19 -5 42 7 36 17 20 23 -27
10z"/>
<path d="M1899 1994 c-9 -11 -8 -14 2 -15 8 0 5 -4 -6 -10 -18 -8 -17 -9 4 -5
15 3 47 -6 82 -24 78 -38 92 -27 37 32 -26 26 -42 34 -70 35 -21 1 -42 -5 -49
-13z"/>
<path d="M1125 1988 c-4 -10 -10 -16 -14 -14 -4 3 -19 -8 -34 -24 -18 -19 -23
-30 -15 -33 7 -2 21 8 30 22 10 14 18 22 18 18 0 -5 7 -1 16 7 8 9 14 22 11
29 -3 8 -7 6 -12 -5z"/>
<path d="M2259 1993 c-1 -5 -1 -12 0 -17 1 -5 -5 -6 -13 -3 -10 4 -13 1 -9 -9
3 -7 0 -14 -7 -14 -8 0 -9 -4 -3 -12 5 -7 9 -20 8 -28 0 -8 6 -21 15 -28 13
-10 13 -11 0 -7 -20 7 -52 -49 -37 -64 8 -8 4 -11 -14 -11 -36 0 -49 -18 -49
-69 0 -39 5 -49 32 -73 32 -27 32 -28 13 -45 -11 -11 -14 -12 -8 -3 8 11 5 21
-13 40 l-24 25 0 -51 c0 -50 12 -74 38 -74 9 0 13 7 9 20 -3 10 -2 19 2 18 3
0 13 0 21 1 13 1 13 2 0 11 -13 9 -13 11 0 20 13 9 12 12 -4 25 -23 17 -29 66
-8 58 8 -3 15 3 19 16 3 13 13 21 25 21 18 0 19 -2 7 -17 -8 -10 -20 -22 -26
-26 -18 -11 -16 -27 3 -49 10 -10 18 -31 19 -46 2 -25 -1 -29 -21 -26 -14 1
-21 -2 -18 -8 3 -5 -2 -15 -12 -23 -18 -13 -18 -14 4 -35 12 -11 22 -29 22
-38 0 -10 9 -25 20 -35 11 -9 20 -28 20 -42 0 -28 -12 -32 -30 -10 -15 18 -26
13 -33 -17 -5 -21 -5 -21 7 -5 13 17 32 24 22 8 -4 -5 -1 -11 6 -13 18 -6 -1
-29 -38 -44 -29 -12 -34 -19 -30 -39 3 -14 0 -25 -5 -25 -6 0 -8 -4 -5 -9 4
-5 2 -11 -3 -13 -9 -3 -14 -35 -12 -72 1 -12 6 -16 17 -12 10 4 22 -1 30 -11
16 -22 19 -57 3 -48 -6 4 -13 -2 -16 -14 -4 -16 -11 -19 -28 -15 -20 5 -24 0
-35 -48 -7 -29 -23 -74 -37 -100 -13 -27 -21 -48 -18 -48 12 0 -122 -112 -163
-136 -64 -38 -164 -73 -244 -85 -66 -10 -87 -20 -88 -41 0 -5 9 -8 20 -8 11 0
20 6 20 13 0 8 7 14 15 14 8 0 15 -5 15 -10 0 -6 9 -2 20 8 19 18 20 17 20 -4
l0 -22 30 21 c16 12 34 19 39 16 5 -3 11 1 15 9 3 8 10 15 17 15 6 0 8 -7 5
-16 -5 -14 -4 -15 9 -4 8 7 15 9 15 4 0 -5 7 2 16 14 8 12 24 22 35 22 22 0
69 22 101 48 39 31 108 100 108 107 0 4 8 14 18 22 9 8 11 11 3 7 -9 -5 -12
-3 -7 4 5 9 13 9 27 1 12 -6 23 -8 27 -5 3 3 0 6 -7 6 -8 0 -11 7 -7 15 3 9
-1 18 -8 21 -8 3 -12 14 -9 24 8 31 42 8 36 -24 -3 -16 2 -35 15 -50 16 -22
22 -24 31 -12 7 8 13 24 14 35 1 12 4 21 8 21 4 0 6 12 4 27 -1 15 1 37 6 48
7 15 8 13 4 -10 -4 -19 -3 -26 3 -20 5 6 17 48 27 95 14 67 19 149 23 388 4
300 4 302 26 303 21 0 21 0 -1 11 -22 10 -23 17 -23 114 l0 104 26 -11 c15 -5
29 -7 32 -4 3 3 -9 11 -26 17 -20 8 -32 19 -32 30 0 17 -19 26 -21 11z m1
-190 c0 -5 -9 -19 -20 -33 -11 -14 -20 -31 -20 -37 0 -7 -5 -13 -11 -13 -6 0
-8 9 -4 21 6 19 4 21 -19 15 -24 -6 -25 -5 -15 12 7 11 20 17 29 15 9 -2 22 3
29 11 14 17 31 21 31 9z m-53 -566 c-24 -23 -27 -31 -16 -38 11 -7 10 -9 -3
-9 -26 0 -22 32 7 66 14 16 25 34 26 39 0 6 4 1 8 -9 6 -15 0 -27 -22 -49z
m42 -121 l-12 -26 -12 23 c-9 19 -8 23 10 28 27 8 28 6 14 -25z m-84 -97 c-4
-5 0 -9 9 -9 8 0 17 6 20 13 3 6 3 1 0 -13 -4 -14 -11 -28 -16 -31 -7 -4 -6
-14 1 -27 8 -14 8 -23 1 -27 -6 -4 -9 -12 -8 -19 2 -6 -1 -12 -7 -12 -5 0 -9
6 -7 12 1 7 -2 15 -8 19 -8 5 -7 12 2 23 10 12 10 14 1 8 -7 -4 -13 -2 -13 3
0 6 5 11 10 11 13 0 9 32 -4 41 -11 9 3 31 15 23 5 -3 7 -10 4 -15z"/>
<path d="M683 1954 c-10 -21 -10 -24 2 -14 10 8 15 8 20 0 5 -8 10 -9 18 -1 7
7 6 15 -3 26 -17 21 -23 19 -37 -11z"/>
<path d="M2894 1969 c-3 -6 -15 -9 -25 -6 -24 6 -31 -4 -14 -20 10 -9 18 -6
36 13 12 13 20 24 16 24 -3 0 -9 -5 -13 -11z"/>
<path d="M2078 1950 c12 -12 28 -17 38 -13 14 5 13 8 -7 19 -38 21 -56 17 -31
-6z"/>
<path d="M423 1949 c21 -13 22 -29 1 -29 -24 0 -11 -25 15 -28 17 -3 22 -9 18
-24 -5 -18 -4 -19 12 -4 10 9 23 16 29 16 6 0 9 7 6 15 -5 12 -15 14 -43 9
-24 -4 -32 -2 -22 4 18 13 5 52 -17 52 -16 -1 -15 -2 1 -11z"/>
<path d="M1170 1955 c-6 -37 -10 -50 -19 -53 -17 -6 -13 -22 4 -22 9 0 18 7
21 15 5 11 11 12 25 4 16 -8 19 -6 19 11 0 18 -5 22 -27 19 -4 0 -10 8 -14 18
-4 10 -8 14 -9 8z"/>
<path d="M1852 1947 c-11 -13 -9 -67 3 -86 6 -11 9 -10 17 0 8 11 9 10 5 -2
-3 -9 3 -26 14 -38 21 -22 39 -20 39 4 0 7 12 27 26 44 29 34 25 39 -11 15
-22 -14 -25 -14 -45 6 -13 13 -19 28 -15 39 7 22 -19 36 -33 18z"/>
<path d="M2487 1953 c-16 -15 -6 -51 15 -56 30 -8 31 -5 12 31 -17 35 -17 35
-27 25z"/>
<path d="M837 1933 c-11 -11 -8 -45 4 -38 6 4 16 -1 23 -11 9 -11 19 -15 31
-10 17 6 17 7 0 26 -11 13 -25 18 -37 14 -13 -4 -16 -1 -12 10 6 17 2 21 -9 9z"/>
<path d="M2051 1908 c2 -26 17 -63 18 -45 1 9 6 17 11 17 6 0 10 9 10 20 0 13
-7 20 -20 20 -11 0 -20 -6 -19 -12z"/>
<path d="M2384 1907 c3 -10 6 -30 6 -43 0 -24 1 -24 30 -9 17 9 30 19 30 24 0
8 -28 19 -47 19 -3 0 -10 6 -15 14 -7 9 -8 8 -4 -5z"/>
<path d="M121 1885 c-1 -24 8 -39 42 -70 l42 -40 -37 40 c-20 22 -38 54 -41
70 l-4 30 -2 -30z"/>
<path d="M673 1903 c-19 -7 -77 -63 -66 -63 5 0 27 16 49 35 39 35 41 38 17
28z"/>
<path d="M805 1890 c-3 -5 5 -14 19 -20 26 -9 32 -21 19 -33 -3 -4 -12 1 -19
11 -12 15 -13 15 -14 1 0 -9 -7 -20 -15 -23 -11 -4 -15 0 -13 12 2 10 -3 17
-12 17 -13 0 -15 -28 -17 -190 -1 -134 2 -192 10 -197 9 -6 9 -12 0 -28 -17
-26 -16 -33 2 -26 18 7 18 5 4 -31 -20 -50 -21 -94 -3 -107 14 -10 14 -15 3
-27 -13 -13 -14 -28 -6 -141 1 -19 8 -28 20 -28 9 0 17 -7 17 -15 0 -18 -16
-20 -24 -2 -4 6 -4 -4 0 -23 3 -19 8 -53 10 -75 7 -54 25 -82 23 -35 -1 19 -1
46 0 60 1 23 2 24 19 6 16 -16 16 -19 1 -29 -14 -9 -15 -16 -3 -59 15 -58 36
-94 73 -126 19 -16 23 -24 14 -30 -9 -6 -4 -17 17 -41 17 -18 34 -30 39 -26 5
3 13 0 17 -6 4 -8 3 -9 -4 -5 -7 4 -12 4 -13 -1 -6 -50 -5 -61 8 -56 7 3 14
-1 15 -8 5 -50 18 -79 34 -79 8 0 14 5 14 11 0 5 -4 8 -9 4 -5 -3 -12 -1 -15
4 -7 10 13 9 30 -2 7 -4 15 0 18 9 9 24 8 27 -11 20 -10 -4 -15 -4 -11 -1 8 9
-45 61 -58 57 -6 -1 -11 4 -11 13 0 22 30 19 45 -5 7 -12 18 -17 29 -13 11 4
14 3 9 -5 -9 -15 48 -44 142 -72 59 -18 76 -20 85 -10 9 10 8 12 -5 7 -25 -9
-33 11 -13 33 11 12 14 26 10 39 -8 26 -29 16 -22 -11 4 -12 1 -18 -6 -15 -7
3 -11 10 -9 16 1 7 -2 10 -8 6 -5 -3 -10 -15 -10 -26 0 -10 -5 -19 -11 -19 -6
0 -9 6 -6 13 3 8 1 20 -4 28 -7 12 -9 12 -10 -1 -1 -8 0 -23 1 -33 1 -16 -1
-16 -24 4 -17 14 -26 33 -26 51 0 16 -5 27 -11 25 -6 -1 -14 4 -17 12 -3 10 0
12 12 8 13 -5 17 -2 14 11 -2 11 -11 16 -26 14 -12 -2 -29 4 -38 12 -8 9 -24
16 -35 16 -11 0 -31 7 -44 16 -31 20 -32 8 -2 -24 19 -21 19 -24 4 -19 -10 4
-27 7 -38 7 -15 0 -18 5 -13 24 3 13 1 27 -5 30 -20 12 -12 34 14 38 14 3 25
2 25 -2 0 -3 10 -17 22 -29 l21 -23 12 22 c9 17 14 20 20 9 5 -8 4 -14 -5 -17
-7 -3 5 -13 26 -23 48 -22 65 -24 58 -4 -3 8 -9 14 -12 13 -21 -2 -32 3 -26
14 4 6 -1 17 -11 24 -10 8 -16 17 -13 20 10 10 49 -7 45 -19 -5 -15 24 -47 36
-40 6 3 7 1 3 -5 -5 -8 0 -12 13 -12 14 0 21 -6 21 -20 0 -11 6 -20 14 -20 7
0 25 -9 39 -20 14 -11 32 -20 41 -20 9 0 17 -7 18 -15 0 -8 1 -21 2 -27 1 -7
6 -16 11 -20 6 -5 5 -8 -2 -8 -20 0 -15 -47 4 -54 10 -4 60 -10 113 -13 l95
-6 11 42 c6 23 7 44 3 47 -5 3 -9 10 -9 16 0 7 6 6 15 -2 12 -10 15 -10 15 4
0 12 -10 16 -37 16 -71 0 -155 19 -224 51 -67 31 -105 59 -80 59 6 0 11 4 11
10 0 5 -7 7 -15 4 -9 -4 -15 0 -15 7 0 11 -3 11 -10 -1 -7 -11 -10 -5 -10 23
0 20 -4 37 -10 37 -5 0 -10 -7 -10 -15 0 -17 -16 -20 -25 -4 -4 6 3 21 15 34
21 22 20 45 -1 45 -6 0 -7 -4 -4 -10 3 -5 -4 -10 -16 -10 -19 0 -20 2 -8 16
12 15 11 16 -9 10 -14 -5 -22 -3 -22 4 0 6 -5 8 -10 5 -5 -3 -10 0 -10 9 0 8
6 16 13 19 6 2 9 8 4 12 -4 5 -18 -2 -32 -15 -19 -18 -22 -26 -13 -32 10 -6 9
-11 -4 -19 -10 -5 -18 -17 -18 -25 0 -12 4 -12 34 0 39 16 72 5 38 -13 -11 -6
-27 -11 -35 -11 -8 0 -19 -10 -26 -22 -7 -14 -9 -15 -6 -5 4 15 1 17 -15 12
-11 -4 -20 -2 -20 4 0 6 5 11 10 11 6 0 10 4 10 10 0 5 -6 7 -14 4 -14 -6 -40
27 -31 40 3 3 -1 2 -7 -4 -18 -15 -32 -12 -19 4 6 7 9 21 5 30 -6 15 11 22 40
17 5 -1 6 5 3 13 -4 9 -1 13 6 10 17 -6 15 35 -3 63 -7 11 -20 19 -27 16 -8
-3 -13 3 -13 16 0 14 5 19 15 15 8 -4 15 -1 15 5 0 6 -5 11 -11 11 -6 0 -8 7
-5 16 3 8 2 12 -4 9 -6 -3 -10 0 -10 8 0 7 -11 19 -24 25 -19 8 -26 7 -39 -9
-18 -20 -16 -22 23 -19 23 1 24 1 7 -12 -24 -18 -19 -60 7 -64 10 -1 22 -7 25
-12 3 -5 17 -15 30 -21 17 -10 20 -16 12 -24 -9 -9 -14 -8 -21 2 -7 12 -11 12
-24 -1 -17 -17 -11 -29 10 -21 8 3 14 0 14 -6 0 -16 -29 -14 -46 3 -16 16 -18
27 -5 23 9 -2 11 2 11 29 0 8 -5 12 -10 9 -6 -4 -8 -10 -5 -15 6 -10 -32 -20
-50 -14 -14 6 -27 64 -14 64 4 0 10 -11 13 -25 4 -14 13 -25 21 -25 20 0 19 9
-2 32 -12 14 -14 21 -6 26 8 5 9 13 3 22 -8 12 -10 12 -16 -3 -3 -9 -14 -17
-23 -17 -14 0 -14 2 -1 10 12 8 11 10 -7 10 -18 0 -22 5 -20 23 1 16 -3 22
-15 21 -10 -1 -16 2 -13 6 3 5 1 11 -4 14 -9 6 -6 104 4 114 3 3 11 0 17 -6 8
-8 8 -12 1 -12 -6 0 -13 -19 -15 -41 -3 -31 0 -43 12 -47 8 -3 17 -9 20 -13 3
-4 12 -4 19 0 11 7 9 10 -8 16 -24 7 -39 51 -23 67 8 8 15 6 26 -4 11 -12 12
-18 3 -27 -9 -9 -7 -16 11 -29 22 -17 22 -17 2 -30 -12 -7 -18 -17 -15 -22 3
-6 0 -10 -7 -11 -7 0 -4 -4 7 -9 12 -4 24 -4 30 2 7 7 6 12 -2 15 -8 3 -5 11
9 25 l20 20 -26 17 c-20 13 -23 20 -14 29 16 16 38 15 38 0 0 -9 3 -8 10 2 8
13 11 12 24 -5 9 -13 11 -24 5 -30 -12 -12 72 -34 88 -23 6 5 10 17 8 26 -2 9
-4 22 -5 27 0 6 -5 2 -11 -8 -6 -9 -15 -14 -21 -11 -6 4 -14 0 -19 -7 -4 -7
-10 -11 -13 -9 -2 3 2 14 10 25 13 17 16 18 25 5 9 -12 10 -11 6 6 -2 12 -1
28 4 35 7 11 9 10 9 -3 0 -10 5 -18 10 -18 6 0 10 37 10 91 0 69 3 89 13 83
10 -6 10 -4 0 7 -9 9 -13 50 -13 131 0 101 -2 118 -16 118 -9 0 -18 9 -21 20
-3 11 -9 20 -15 20 -5 0 -4 -8 1 -19 9 -16 8 -18 -9 -14 -11 3 -17 10 -13 16
3 5 1 7 -5 4 -5 -4 -8 -16 -5 -27 6 -23 -6 -26 -27 -7 -8 6 -6 2 4 -10 16 -19
20 -20 33 -8 19 20 22 19 16 -5 -9 -33 -47 -24 -89 19 l-39 40 -40 -37 c-22
-20 -35 -31 -28 -23 6 7 9 19 6 26 -3 8 5 15 19 17 24 3 33 32 11 34 -7 1 -19
2 -26 3 -14 1 -18 81 -4 81 5 0 6 4 3 9 -3 5 1 11 9 15 8 3 15 12 15 20 0 10
10 14 30 14 30 -1 44 -18 15 -18 -8 0 -15 -9 -15 -20 0 -11 -7 -23 -15 -26
-21 -8 -19 -27 4 -44 11 -7 18 -16 16 -20 -6 -10 63 -52 74 -45 17 10 13 42
-5 49 -25 10 -30 6 -11 -8 15 -12 16 -14 2 -20 -9 -3 -15 0 -15 10 0 10 -6 14
-15 10 -8 -3 -15 -1 -15 3 0 5 7 11 15 15 20 7 19 26 -1 26 -18 0 -14 22 6 28
7 3 10 0 6 -7 -4 -6 0 -11 9 -11 9 0 14 -4 11 -8 -6 -11 11 -38 19 -30 4 3 0
19 -8 34 -13 25 -13 31 0 50 11 17 11 24 2 30 -7 4 -15 2 -17 -5 -6 -17 -32 6
-32 28 -1 16 -30 62 -30 47 0 -5 -6 -3 -14 3 -10 8 -16 9 -21 1z m53 -268 c13
-9 6 -42 -9 -42 -5 0 -9 -5 -9 -11 0 -5 4 -7 10 -4 7 4 7 -3 0 -19 -8 -22 -7
-26 10 -26 14 0 19 6 17 22 -2 21 2 23 55 23 51 0 57 -2 61 -23 2 -12 0 -25
-6 -29 -6 -3 -7 -1 -3 5 4 7 2 12 -3 12 -16 0 -13 -17 5 -45 15 -23 15 -29 1
-72 -10 -29 -12 -54 -7 -67 5 -12 9 -15 9 -7 1 8 3 12 6 9 3 -3 1 -16 -4 -29
-7 -17 -10 -19 -10 -6 -1 9 -8 17 -16 17 -8 0 -25 16 -37 35 -26 39 -38 44
-38 15 0 -12 7 -20 17 -20 12 0 14 -3 7 -10 -13 -13 -44 -3 -45 14 0 6 -2 21
-3 34 -2 12 1 22 5 22 4 0 15 3 25 7 13 5 15 3 10 -8 -4 -8 0 -5 8 5 11 14 19
17 26 10 8 -8 6 -15 -5 -24 -17 -14 -21 -35 -5 -25 6 3 10 2 10 -4 0 -16 28
-2 35 17 5 14 4 15 -6 5 -22 -22 -30 -14 -13 12 9 14 13 25 10 25 -4 0 -2 9 4
21 15 27 -4 52 -33 43 -16 -5 -19 -3 -14 14 3 12 -1 26 -9 33 -11 9 -14 7 -14
-9 0 -24 -17 -38 -47 -40 -27 -1 -40 15 -24 31 7 7 5 14 -7 24 -15 12 -15 14
-2 26 9 6 14 20 12 30 -4 18 5 22 26 9z m-68 -112 c0 -16 -4 -30 -9 -30 -14 0
-20 37 -8 49 16 16 17 14 17 -19z m72 -38 c8 -31 -1 -43 -24 -31 -15 8 -22 8
-28 -1 -5 -9 -9 -9 -14 -1 -4 6 -3 11 3 11 6 0 13 11 17 24 6 27 39 26 46 -2z
m98 13 c0 -8 -5 -15 -11 -15 -5 0 -7 -5 -3 -11 4 -7 1 -10 -7 -7 -8 3 -24 1
-36 -3 -33 -12 -28 2 10 29 37 26 47 27 47 7z m-107 -117 c7 -4 11 -25 11 -47
-1 -27 3 -42 13 -46 11 -3 13 -1 9 10 -3 8 -1 15 5 15 7 0 9 -10 5 -24 -5 -19
-1 -25 19 -30 16 -4 25 -13 25 -26 0 -28 -16 -25 -47 8 -17 18 -27 23 -30 15
-5 -15 -33 -18 -33 -4 0 5 10 11 22 15 18 4 20 8 10 18 -10 10 -15 10 -22 -2
-8 -12 -10 -9 -10 12 0 15 -5 30 -12 34 -6 4 -8 3 -4 -4 9 -16 -34 -26 -48
-12 -8 10 -8 11 1 6 18 -10 24 9 13 38 -9 25 -8 26 10 16 11 -6 27 -22 36 -35
9 -14 20 -22 26 -19 5 3 4 12 -4 21 -7 9 -12 18 -10 20 7 10 -1 21 -23 31 -28
13 -34 32 -10 32 8 0 15 8 16 18 0 9 5 1 11 -18 5 -19 15 -38 21 -42z m110
-70 c9 -18 13 -37 11 -43 -3 -5 1 -2 9 8 18 22 23 22 23 -3 0 -17 -17 -21 -46
-10 -3 2 -8 2 -12 1 -5 0 -8 5 -8 12 0 8 -4 7 -10 -3 -8 -13 -10 -13 -10 2 0
23 20 34 31 17 7 -11 9 -10 9 4 0 10 -8 17 -19 17 -21 0 -34 13 -24 24 14 14
31 4 46 -26z m-67 -346 c-3 -2 1 -11 9 -19 21 -20 19 -47 -5 -68 -16 -14 -17
-20 -8 -27 9 -5 6 -8 -9 -8 -13 0 -23 6 -23 14 0 16 24 39 32 30 3 -2 8 2 12
11 4 13 1 15 -14 10 -25 -8 -27 10 -2 20 16 7 15 8 -3 15 -27 12 -37 12 -30 1
3 -5 1 -12 -4 -15 -13 -8 -24 11 -17 29 3 8 18 13 36 13 16 -1 28 -3 26 -6z
m204 -307 c0 -16 -6 -25 -15 -25 -8 0 -15 5 -15 10 0 6 4 10 10 10 5 0 7 7 4
15 -4 8 -1 15 5 15 6 0 11 -11 11 -25z m260 -25 c10 -7 12 -12 4 -17 -6 -5
-14 -19 -18 -33 -6 -24 -5 -24 5 -3 12 23 50 35 70 22 8 -4 23 1 37 12 20 15
28 17 40 7 22 -19 31 -51 17 -64 -13 -13 -68 -7 -60 7 3 4 14 6 25 4 26 -5 25
7 -1 23 -12 8 -18 9 -14 2 3 -6 3 -9 -2 -8 -5 2 -19 -7 -33 -19 -35 -33 -57
-31 -24 2 9 9 14 18 11 21 -3 3 -13 -3 -23 -14 -34 -34 -85 -36 -64 -2 5 9 5
19 -2 27 -11 13 -4 43 9 43 4 0 15 -5 23 -10z"/>
<path d="M2580 1890 c0 -5 -14 -10 -31 -10 -24 0 -30 -3 -25 -16 3 -9 6 -22 6
-30 0 -8 6 -11 15 -8 13 5 13 8 0 21 -20 19 -19 20 8 18 14 -1 21 -8 21 -19
-1 -9 2 -16 6 -16 4 0 8 16 7 35 -1 19 -2 35 -4 35 -2 0 -3 -4 -3 -10z"/>
<path d="M2015 1880 c-5 -9 -4 -80 2 -80 8 0 29 58 26 73 -5 19 -18 23 -28 7z"/>
<path d="M2360 1881 c0 -5 -7 -16 -14 -24 -13 -13 -12 -14 10 -14 17 0 24 5
24 18 0 10 -4 21 -10 24 -5 3 -10 2 -10 -4z"/>
<path d="M2645 1873 c-20 -15 -20 -15 0 -12 28 3 57 -11 78 -38 20 -25 39 -31
35 -11 -4 16 -47 52 -66 55 -8 1 -18 6 -21 11 -4 6 -14 4 -26 -5z"/>
<path d="M2157 1856 c-3 -8 -19 -17 -36 -21 -17 -4 -31 -11 -31 -15 0 -5 7 -7
15 -4 8 4 15 1 15 -6 0 -6 -9 -14 -20 -17 -11 -3 -20 -9 -20 -14 0 -5 -5 -9
-11 -9 -7 0 -9 11 -5 30 7 39 -2 37 -43 -11 -19 -22 -28 -39 -20 -37 8 2 13
-2 12 -8 -1 -6 -4 -23 -7 -38 -2 -14 -1 -28 4 -31 13 -8 28 25 22 47 -3 13 0
19 9 17 20 -4 76 32 90 58 7 12 15 20 19 18 7 -4 40 37 40 50 0 11 -28 4 -33
-9z"/>
<path d="M1828 1845 c12 -25 50 -60 57 -53 3 2 -12 20 -31 39 -23 21 -33 26
-26 14z"/>
<path d="M1962 1820 c-18 -21 -43 -41 -55 -43 -19 -4 -18 -4 5 -6 29 -1 88 49
88 75 0 18 0 19 -38 -26z"/>
<path d="M2440 1842 c0 -14 7 -19 26 -19 15 0 21 3 14 7 -6 4 -11 11 -10 16 0
5 -6 10 -14 12 -10 2 -16 -4 -16 -16z"/>
<path d="M2644 1823 c2 -16 11 -45 20 -65 15 -35 15 -37 -1 -31 -31 11 -34 15
-23 28 15 18 -6 31 -25 15 -12 -10 -11 -14 2 -30 18 -20 29 -65 15 -56 -5 3
-18 -1 -28 -9 -12 -9 -14 -14 -6 -15 23 0 52 20 52 37 0 14 2 14 15 -3 17 -22
20 -38 5 -29 -5 3 -10 1 -10 -5 0 -6 -5 -8 -11 -4 -8 5 -8 1 0 -13 6 -11 7
-23 4 -27 -3 -3 6 -6 20 -6 21 0 25 -4 21 -17 -5 -15 -4 -16 5 -3 8 11 7 18
-2 25 -12 9 -13 16 -9 54 1 8 8 11 18 7 14 -5 15 -4 5 8 -7 8 -20 12 -29 9
-14 -5 -15 -3 -4 8 6 7 12 21 12 31 0 10 5 18 11 18 5 0 8 -4 4 -9 -3 -5 0
-13 6 -17 8 -5 10 1 7 20 -5 26 6 37 15 14 3 -8 6 -5 6 5 2 31 -19 58 -54 73
-45 18 -45 18 -41 -13z m46 -13 c0 -5 7 -7 15 -4 22 9 18 1 -12 -27 -14 -13
-23 -18 -18 -10 4 8 2 17 -6 21 -7 5 -13 18 -14 29 -1 16 2 18 17 10 10 -5 18
-14 18 -19z"/>
<path d="M1090 1815 c-14 -17 -5 -35 19 -35 24 0 30 8 24 31 -6 23 -26 25 -43
4z"/>
<path d="M1150 1815 c6 -8 9 -18 6 -24 -8 -12 18 -21 33 -11 9 5 8 9 -4 14
-10 3 -15 12 -11 21 4 10 -1 15 -16 15 -17 0 -18 -3 -8 -15z"/>
<path d="M641 1785 c-25 -43 -27 -52 -4 -34 9 8 24 13 35 11 10 -2 18 1 18 7
0 6 -7 8 -15 5 -8 -4 -15 -2 -15 4 0 6 4 12 10 14 5 2 6 9 1 16 -6 10 -14 4
-30 -23z"/>
<path d="M705 1753 c0 -12 6 -23 13 -23 6 0 12 -7 12 -17 0 -9 2 -14 5 -12 2
3 2 21 -1 40 -7 40 -29 49 -29 12z"/>
<path d="M541 1754 c-1 -31 -12 -36 -38 -19 -22 15 -23 14 -23 -12 -1 -25 -2
-26 -14 -10 -7 9 -17 17 -21 17 -4 0 5 -16 20 -36 29 -38 30 -57 0 -24 -10 11
-22 20 -27 20 -5 0 1 -10 12 -22 11 -12 18 -28 16 -35 -3 -7 -1 -13 4 -13 6 0
10 5 10 11 0 5 5 7 12 3 6 -4 8 -3 4 4 -6 11 10 27 29 27 6 0 15 15 21 34 6
19 20 38 30 42 17 7 17 8 -3 8 -12 1 -24 7 -26 14 -3 6 -6 3 -6 -9z m-21 -55
c0 -10 -7 -19 -15 -19 -9 0 -14 9 -13 24 3 28 28 24 28 -5z"/>
<path d="M1232 1763 c-6 -3 -9 -10 -5 -16 3 -6 7 -26 7 -45 1 -43 19 -51 34
-17 13 29 7 49 -10 39 -6 -4 -9 -3 -6 2 9 14 -6 41 -20 37z"/>
<path d="M1281 1757 c-6 -8 -8 -17 -5 -20 8 -7 54 14 54 25 0 13 -36 9 -49 -5z"/>
<path d="M2411 1763 c-1 -16 19 -28 46 -28 15 -1 19 3 13 10 -6 7 -7 15 -3 18
3 4 1 7 -5 7 -7 0 -12 -4 -12 -10 0 -14 -27 -12 -33 3 -4 9 -6 9 -6 0z"/>
<path d="M1036 1751 c-17 -6 -17 -8 -4 -14 13 -4 16 -19 14 -66 -1 -37 2 -59
9 -58 5 1 10 -8 9 -20 0 -13 5 -23 12 -23 7 0 15 -6 17 -12 4 -9 6 -8 6 3 1 8
-5 21 -11 28 -11 10 -10 11 3 6 10 -4 20 -1 24 4 3 6 11 9 16 6 5 -4 10 -3 10
2 7 52 7 73 0 78 -6 3 -6 18 0 38 11 39 11 37 0 37 -5 0 -13 -9 -16 -20 -4
-13 -15 -20 -31 -20 -17 0 -24 5 -24 20 0 21 -4 23 -34 11z m70 -57 c10 -8 13
-13 6 -9 -7 4 -15 -2 -20 -14 -7 -21 -7 -21 -25 -1 -9 11 -12 20 -6 20 5 0 7
5 4 10 -10 16 19 12 41 -6z m18 -68 c-7 -17 -29 -21 -38 -7 -3 5 1 11 9 15 8
3 15 13 15 23 1 16 1 17 10 1 6 -9 7 -23 4 -32z"/>
<path d="M2418 1695 c2 -14 9 -28 15 -32 6 -4 8 0 4 11 -4 10 -1 19 9 23 13 5
13 7 2 14 -24 16 -35 10 -30 -16z"/>
<path d="M604 1698 c-3 -5 -2 -8 3 -7 4 1 17 1 28 0 16 -1 13 -5 -11 -15 -43
-19 -48 -31 -14 -32 20 -1 32 6 44 27 9 15 16 29 16 31 0 8 -61 4 -66 -4z"/>
<path d="M2107 1704 c-11 -11 -8 -26 3 -19 6 3 10 2 10 -3 0 -6 -7 -13 -16
-16 -12 -5 -14 -10 -5 -20 6 -7 8 -16 4 -19 -3 -4 -4 -7 -1 -7 3 0 12 10 21
23 12 19 13 27 3 45 -7 13 -15 19 -19 16z"/>
<path d="M1277 1663 c-11 -28 -12 -40 -3 -45 20 -13 28 -9 21 12 -8 26 6 26
22 1 9 -15 9 -21 1 -23 -9 -3 -9 -6 0 -12 7 -4 12 -2 12 5 0 8 5 7 18 -3 9 -9
30 -18 45 -20 l29 -6 -34 33 c-24 24 -36 30 -45 23 -9 -7 -14 -6 -19 5 -3 9
-4 20 -1 24 2 4 -3 16 -13 26 -17 17 -18 16 -33 -20z"/>
<path d="M2051 1674 c-20 -25 -20 -44 -1 -44 5 0 7 6 4 14 -3 7 2 21 12 30 9
9 14 19 11 22 -2 3 -14 -7 -26 -22z"/>
<path d="M2366 1693 c-3 -3 -1 -12 5 -19 7 -9 7 -19 -2 -36 -7 -13 -12 -24
-11 -25 1 -2 2 -12 2 -24 0 -13 -4 -18 -12 -13 -7 4 -8 3 -4 -5 6 -9 11 -9 21
-1 11 9 14 7 12 -9 -1 -15 -6 -19 -19 -15 -16 5 -16 3 0 -34 26 -62 38 -42 37
66 0 89 -11 133 -29 115z"/>
<path d="M2500 1661 c0 -5 5 -13 11 -16 6 -4 8 -13 5 -21 -3 -8 -1 -14 5 -14
7 0 9 -10 5 -24 -6 -24 14 -52 27 -39 11 10 8 35 -3 28 -5 -3 -10 1 -10 11 0
11 6 15 18 12 12 -3 18 1 18 11 0 9 -8 17 -17 17 -10 0 -15 -4 -13 -8 3 -4 0
-8 -5 -8 -6 0 -11 7 -11 16 0 8 -7 22 -15 30 -8 9 -15 11 -15 5z"/>
<path d="M537 1643 c-17 -4 -16 -8 9 -34 22 -23 26 -32 15 -38 -10 -7 -9 -10
4 -14 9 -4 13 -3 10 3 -11 17 11 11 28 -8 21 -23 21 -32 3 -32 -9 0 -12 -7 -9
-20 6 -23 -15 -28 -35 -8 -7 7 -12 9 -12 6 0 -12 51 -63 63 -63 6 0 12 -6 15
-12 2 -8 -5 -13 -17 -13 -12 0 -21 -6 -21 -15 0 -9 9 -15 23 -15 18 0 19 2 7
10 -12 8 -11 10 6 10 26 0 38 -24 20 -39 -9 -7 -16 -7 -26 2 -9 8 -11 9 -6 0
3 -6 2 -14 -3 -17 -5 -4 -12 2 -14 11 -2 10 -4 -13 -4 -52 1 -38 -2 -76 -7
-84 -6 -9 -5 -12 2 -7 6 3 14 1 19 -6 4 -7 19 -19 32 -26 12 -8 20 -9 17 -4
-3 6 0 12 6 15 7 2 9 8 5 13 -4 5 -12 2 -17 -6 -7 -10 -10 -11 -10 -2 0 6 -7
12 -15 12 -8 0 -15 5 -15 11 0 6 9 9 20 6 13 -3 20 0 20 9 0 8 -6 14 -14 14
-13 0 -22 18 -32 62 -6 25 48 34 80 13 19 -13 24 -24 23 -54 -1 -21 -8 -45
-16 -54 -7 -9 -10 -24 -7 -33 4 -9 1 -23 -5 -31 -10 -11 -15 -11 -30 3 -12 11
-19 13 -19 5 0 -7 -4 -10 -9 -7 -5 3 -11 0 -14 -8 -4 -9 0 -12 13 -9 10 3 21
0 24 -6 5 -8 13 -8 27 0 18 9 19 8 7 -10 -9 -14 -9 -24 -2 -31 8 -8 11 -7 12
2 2 24 20 83 21 70 1 -7 6 -10 12 -7 6 4 7 19 4 36 -4 16 -1 46 6 66 9 28 9
38 0 41 -6 2 -11 10 -11 18 0 8 -7 14 -15 14 -8 0 -15 5 -15 10 0 6 5 10 11
10 5 0 7 5 3 11 -4 8 -9 8 -17 0 -16 -16 -26 -13 -20 7 2 10 -5 28 -16 41 -11
13 -18 28 -14 34 3 5 1 7 -4 4 -9 -6 -23 12 -23 29 0 12 30 2 39 -13 5 -10 13
-15 16 -11 9 8 -19 38 -34 38 -6 0 -11 7 -11 15 0 8 4 15 10 15 5 0 7 6 4 14
-3 8 -1 17 4 20 16 10 44 -5 38 -21 -4 -10 0 -13 11 -11 9 2 18 8 20 13 4 12
-95 81 -103 72 -9 -9 22 -37 31 -29 5 4 5 1 1 -5 -11 -20 -34 -15 -42 10 -5
12 -7 25 -6 30 1 4 -2 7 -8 7 -5 0 -10 7 -10 15 0 15 -2 16 -33 8z"/>
<path d="M2420 1627 c0 -33 12 -40 36 -22 19 15 19 15 0 8 -12 -4 -17 -3 -13
4 4 6 0 16 -8 23 -13 10 -15 8 -15 -13z"/>
<path d="M2624 1622 c4 -7 3 -19 -4 -27 -8 -9 -8 -15 0 -20 8 -5 6 -14 -4 -30
-9 -14 -12 -26 -6 -30 5 -3 16 5 25 19 17 25 14 72 -6 90 -9 8 -10 8 -5 -2z"/>
<path d="M1255 1600 c-3 -5 -3 -20 1 -34 5 -20 9 -23 19 -13 7 7 11 15 9 19
-2 3 2 9 8 11 10 4 9 8 -2 16 -19 13 -27 14 -35 1z"/>
<path d="M1950 1584 c0 -6 7 -18 16 -25 9 -7 19 -22 23 -33 4 -11 11 -15 14
-9 3 5 -7 25 -23 44 -16 19 -29 29 -30 23z"/>
<path d="M1292 1555 c-6 -14 -16 -25 -22 -25 -5 0 -10 -6 -10 -14 0 -8 -5 -18
-12 -22 -7 -4 -8 -3 -4 4 9 15 -4 63 -15 56 -5 -3 -9 0 -9 5 0 6 -9 11 -20 11
-11 0 -20 -4 -20 -9 0 -15 29 -33 41 -25 8 4 9 3 5 -4 -4 -7 -2 -12 4 -12 7 0
5 -7 -4 -16 -16 -16 -23 -48 -8 -42 4 2 19 10 34 17 22 11 30 12 43 1 18 -15
11 -30 -16 -30 -10 0 -19 -7 -19 -15 0 -8 2 -15 4 -15 13 0 95 40 101 49 3 6
11 9 16 6 5 -4 9 -1 9 4 0 6 9 11 20 11 15 0 20 -7 20 -26 0 -22 3 -24 14 -15
27 23 12 122 -16 105 -6 -4 -5 -10 3 -15 11 -7 10 -11 -6 -23 -11 -9 -28 -16
-37 -16 -9 0 -23 -7 -32 -15 -19 -19 -30 -19 -47 2 -10 12 -11 17 -2 20 7 2
10 10 7 17 -3 8 4 16 17 19 20 5 20 7 6 21 -22 22 -32 20 -45 -9z"/>
<path d="M2056 1571 c-4 -5 2 -12 13 -16 16 -5 19 -14 17 -45 -2 -22 0 -37 4
-35 21 13 16 -28 -5 -51 -25 -27 -24 -50 1 -40 8 3 14 1 14 -4 0 -6 -4 -10
-10 -10 -5 0 -10 -7 -10 -16 0 -9 -8 -18 -18 -21 -14 -3 -18 -11 -14 -30 4
-22 2 -24 -17 -18 -25 8 -28 -7 -5 -30 16 -15 19 -15 40 12 13 16 21 36 18 46
-4 12 0 17 16 17 19 0 21 4 15 28 -11 40 -13 66 -6 77 3 6 7 38 8 73 1 47 -1
62 -12 62 -7 0 -20 3 -28 6 -8 3 -18 1 -21 -5z"/>
<path d="M2433 1565 c-16 -17 -17 -55 -2 -55 5 0 7 4 4 10 -3 5 2 21 11 35 17
27 8 34 -13 10z"/>
<path d="M2492 1545 c6 -13 15 -22 19 -19 5 3 7 -8 5 -24 -2 -22 1 -29 12 -28
9 2 18 9 20 16 3 7 0 10 -7 6 -6 -4 -11 0 -11 11 -1 17 -32 63 -44 63 -3 0 -1
-11 6 -25z"/>
<path d="M1163 1521 c0 -17 4 -28 8 -25 4 2 10 0 13 -5 4 -5 2 -12 -4 -16 -15
-9 -12 -25 4 -25 8 0 15 14 18 34 2 20 0 34 -6 33 -6 -1 -10 4 -11 12 0 29
-22 21 -22 -8z"/>
<path d="M1376 1535 c-19 -20 -20 -25 -3 -25 24 0 43 18 31 30 -8 8 -16 7 -28
-5z"/>
<path d="M2151 1430 c1 -74 5 -115 9 -100 5 14 8 21 9 15 1 -13 27 25 35 53 4
12 4 22 -1 21 -19 -3 -25 2 -14 15 15 18 10 43 -17 86 l-22 35 1 -125z"/>
<path d="M2653 1526 c4 -9 2 -16 -3 -16 -6 0 -10 -9 -10 -21 0 -14 5 -19 14
-16 7 3 20 0 27 -6 11 -10 11 -9 1 4 -7 9 -11 27 -10 39 2 13 -3 25 -11 28
-10 3 -12 0 -8 -12z"/>
<path d="M2445 1501 c0 -16 2 -28 5 -26 3 2 10 -8 17 -21 6 -14 7 -24 1 -24
-5 0 -6 -5 -2 -11 5 -8 10 -6 16 5 5 9 17 16 26 17 14 0 14 2 2 6 -30 11 -42
26 -28 34 9 6 8 13 -6 28 -26 28 -32 26 -31 -8z"/>
<path d="M705 1483 c-15 -35 -12 -49 12 -51 13 -1 23 3 23 8 0 6 -5 10 -11 10
-8 0 -8 5 1 15 10 12 10 15 -1 15 -11 0 -11 2 1 10 13 8 13 10 -1 10 -9 0 -20
-8 -24 -17z"/>
<path d="M1496 1474 c-4 -9 -4 -19 -1 -22 3 -3 5 0 5 7 0 7 5 9 10 6 6 -3 10
-17 10 -31 0 -27 12 -31 29 -11 8 10 4 20 -18 41 -25 24 -29 25 -35 10z"/>
<path d="M2543 1459 c-6 -6 -9 -18 -6 -27 5 -12 1 -14 -20 -10 -15 3 -24 1
-21 -3 3 -5 14 -9 24 -9 13 0 17 -5 14 -15 -4 -8 -10 -12 -15 -9 -5 3 -9 -2
-9 -10 0 -9 -5 -16 -12 -16 -6 0 -8 -3 -5 -6 4 -4 13 -1 22 6 8 7 15 9 15 5 0
-5 9 0 19 9 10 10 25 16 33 15 36 -5 43 2 43 41 1 22 -2 34 -6 28 -11 -17 -37
-18 -43 -2 -6 17 -18 18 -33 3z m32 -19 c4 -6 10 -8 15 -5 4 3 10 -2 14 -10 6
-17 -9 -21 -19 -5 -4 6 -11 8 -16 4 -5 -3 -7 -9 -4 -14 3 -4 -1 -10 -8 -13 -9
-3 -13 5 -12 24 0 28 18 39 30 19z"/>
<path d="M1216 1435 c-11 -8 -17 -15 -13 -15 4 0 3 -9 -3 -20 -7 -14 -7 -20 0
-20 5 0 10 -4 10 -10 0 -5 -6 -10 -14 -10 -7 0 -16 -12 -19 -27 l-5 -28 25 29
c13 16 22 35 19 43 -3 8 1 21 9 29 8 9 15 22 15 30 0 17 0 17 -24 -1z"/>
<path d="M1446 1431 c-20 -22 -20 -31 -1 -31 8 0 15 -6 15 -14 0 -7 12 -18 28
-24 30 -12 142 -12 142 0 0 14 -20 19 -40 8 -16 -9 -20 -8 -20 5 0 15 -2 15
-22 1 -28 -20 -54 -6 -63 34 -8 38 -18 44 -39 21z"/>
<path d="M2670 1437 c0 -16 -62 -77 -78 -77 -7 0 -12 6 -12 13 -1 6 -9 2 -20
-11 -12 -15 -21 -19 -26 -11 -5 8 -9 8 -14 -2 -5 -7 -3 -18 5 -25 13 -14 14
-62 1 -82 -5 -8 0 -12 13 -12 11 0 22 -9 26 -19 3 -11 12 -22 20 -25 26 -9 17
-27 -10 -20 -15 4 -25 2 -25 -5 0 -6 7 -11 15 -11 8 0 15 -5 15 -11 0 -6 7 -4
15 5 8 8 15 19 15 25 0 11 13 15 38 12 6 -1 12 3 12 9 0 6 -15 8 -35 6 -32 -4
-35 -2 -30 15 4 11 2 19 -4 19 -6 0 -11 5 -11 10 0 6 8 10 19 10 13 0 20 9 24
33 3 17 0 37 -6 42 -7 7 -8 5 -5 -5 3 -8 -7 -31 -23 -50 -19 -23 -29 -29 -29
-18 0 9 -6 18 -12 21 -10 3 -10 7 1 20 11 13 17 15 28 6 7 -7 16 -9 19 -6 9 9
-5 27 -22 27 -8 0 -14 5 -14 11 0 6 9 9 19 6 11 -3 28 3 40 14 16 15 25 17 40
9 11 -5 26 -7 34 -4 18 7 28 -7 18 -25 -7 -10 -13 -10 -32 1 -13 7 -27 18 -31
23 -5 6 -8 4 -8 -5 0 -16 47 -60 63 -60 6 0 2 7 -9 15 -18 14 -18 14 2 9 12
-3 36 2 53 10 38 20 41 29 4 15 -15 -6 -24 -7 -20 -3 4 4 3 12 -3 18 -5 7 -5
17 0 26 7 10 10 11 10 3 0 -7 4 -13 8 -13 14 0 10 29 -6 35 -8 3 -16 -3 -19
-16 -4 -15 -8 -17 -14 -8 -6 10 -11 10 -21 1 -10 -7 -18 -8 -28 0 -12 8 -12 9
3 4 9 -3 17 0 17 6 0 6 8 17 18 24 16 13 16 13 -3 14 -20 1 -20 1 0 10 19 8
19 9 -2 9 -13 1 -23 -5 -23 -12z"/>
<path d="M2313 1426 c-10 -11 -9 -15 3 -20 8 -3 12 -10 9 -16 -3 -5 1 -10 9
-10 8 0 28 -16 43 -35 31 -39 53 -45 53 -14 0 11 -4 18 -9 15 -12 -8 -22 20
-15 39 4 10 0 15 -11 15 -9 0 -14 4 -10 9 6 11 -26 31 -47 31 -7 -1 -18 -7
-25 -14z"/>
<path d="M1166 1401 c-9 -10 -14 -25 -11 -33 4 -10 -1 -17 -15 -21 -19 -5 -20
-8 -9 -28 11 -21 12 -21 24 -5 7 9 12 28 10 42 -1 13 1 24 5 24 4 0 10 9 13
20 6 25 4 25 -17 1z"/>
<path d="M2451 1358 c1 -29 -1 -63 -5 -75 -5 -15 -3 -23 4 -23 8 0 10 -9 6
-24 -4 -15 -1 -26 7 -29 8 -3 13 4 14 22 1 54 2 54 18 41 12 -10 13 -16 4 -26
-15 -19 -1 -18 17 1 10 10 11 15 4 15 -7 0 -10 7 -7 14 4 10 1 13 -7 10 -8 -3
-17 0 -21 5 -3 6 -11 9 -16 6 -5 -4 -9 -1 -9 4 0 6 3 11 8 11 4 0 7 11 7 25 0
14 -3 25 -7 25 -3 0 -4 8 -1 19 3 10 0 22 -7 26 -8 5 -10 -9 -9 -47z"/>
<path d="M537 1354 c-3 -3 -3 -13 0 -22 4 -9 2 -13 -4 -9 -6 4 -14 0 -17 -9
-6 -15 -4 -15 19 0 14 9 31 14 37 10 7 -4 8 -3 4 4 -4 7 -15 12 -24 13 -14 0
-13 2 3 9 15 6 16 9 4 9 -9 1 -18 -2 -22 -5z"/>
<path d="M1351 1334 c-12 -15 -21 -41 -21 -60 0 -19 -4 -34 -8 -34 -5 0 -14
-16 -22 -35 -16 -41 -26 -44 -34 -12 -6 24 14 91 32 109 16 16 15 38 -2 38
-17 0 -38 -24 -29 -33 3 -4 1 -7 -5 -7 -14 0 -34 35 -25 44 3 3 2 6 -3 6 -13
0 -34 -21 -34 -33 0 -5 6 -7 14 -4 10 4 12 0 8 -15 -3 -15 -1 -19 6 -14 7 4
15 0 18 -8 3 -9 11 -13 17 -9 6 3 7 1 3 -5 -4 -7 -15 -8 -29 -3 -12 4 -27 6
-33 4 -16 -5 -5 -43 12 -43 8 0 11 6 8 15 -4 9 0 15 10 15 18 0 22 -25 6 -35
-14 -8 -13 -45 1 -45 8 0 8 -5 -1 -15 -10 -12 -9 -16 2 -20 20 -8 11 -75 -11
-75 -13 0 -13 -2 -2 -9 7 -5 11 -14 7 -20 -5 -7 -1 -9 9 -5 15 6 29 41 24 60
-2 5 6 24 17 41 21 36 46 37 75 3 9 -10 21 -15 27 -11 9 5 5 14 -12 30 -14 13
-29 20 -34 17 -6 -3 -13 2 -17 11 -4 12 1 24 15 35 12 10 17 18 11 18 -6 0
-10 12 -9 28 2 38 8 45 24 31 16 -13 19 2 4 17 -15 15 -1 46 17 40 8 -3 11 -1
8 4 -10 16 -22 12 -44 -16z"/>
<path d="M653 1316 c-6 -8 -9 -17 -5 -20 3 -4 0 -11 -8 -16 -9 -6 -10 -10 -3
-10 19 0 43 29 37 45 -7 18 -6 18 -21 1z"/>
<path d="M2338 1303 c-10 -13 -10 -15 0 -9 17 10 42 -11 42 -35 0 -11 3 -19 8
-19 26 1 54 57 28 57 -9 0 -13 -4 -10 -9 3 -4 0 -8 -5 -8 -6 0 -11 3 -11 8 0
4 -9 13 -19 19 -16 10 -22 9 -33 -4z"/>
<path d="M533 1293 c-7 -3 -13 -14 -13 -26 0 -20 1 -20 13 -2 11 14 16 15 22
5 5 -8 1 -18 -10 -26 -21 -16 -16 -28 8 -18 21 8 30 34 17 49 -5 6 -8 14 -5
17 6 6 -15 6 -32 1z"/>
<path d="M1098 1283 c-20 -5 -25 -33 -7 -33 7 0 0 -11 -15 -25 -28 -26 -33
-49 -14 -61 8 -5 9 0 5 15 -3 15 1 24 14 31 16 9 19 7 19 -9 0 -11 5 -23 10
-26 13 -8 13 15 0 35 -10 16 6 43 21 34 5 -3 9 -1 9 5 0 16 -12 41 -19 40 -3
-1 -14 -3 -23 -6z"/>
<path d="M2725 1271 c-3 -6 -11 -9 -16 -6 -5 3 -6 -1 -3 -9 4 -10 1 -16 -9
-16 -8 0 -18 6 -20 13 -3 7 -6 5 -6 -5 -1 -10 -6 -18 -12 -18 -5 0 -8 4 -5 9
4 5 -2 8 -11 6 -10 -2 -21 -4 -25 -4 -5 -1 -8 -9 -8 -18 0 -15 2 -16 10 -3 8
12 13 13 29 3 14 -9 22 -9 26 -2 4 5 14 9 23 7 10 -2 22 7 30 21 12 24 10 42
-3 22z"/>
<path d="M1925 1260 c-3 -5 -1 -10 4 -10 17 0 13 -30 -5 -37 -13 -5 -15 -15
-9 -49 4 -29 2 -44 -4 -44 -6 0 -11 -4 -11 -9 0 -13 49 12 55 28 3 8 -1 19 -9
23 -8 5 -13 15 -10 23 4 8 9 13 13 10 12 -7 48 23 54 45 6 24 -10 26 -35 3
-16 -14 -18 -15 -18 0 0 20 -16 31 -25 17z"/>
<path d="M1874 1225 c-1 -16 3 -24 10 -22 16 5 20 47 4 47 -7 0 -14 -11 -14
-25z"/>
<path d="M2072 1242 c3 -15 -15 -42 -28 -42 -10 0 -8 -6 6 -20 24 -24 30 -25
30 -6 0 8 5 18 11 22 7 4 9 0 5 -14 -5 -13 -1 -22 10 -26 14 -5 15 -1 9 31 -4
21 -8 43 -8 48 0 6 -8 12 -18 12 -10 1 -18 -1 -17 -5z"/>
<path d="M2742 1238 c-16 -16 33 -61 67 -60 17 0 41 -15 70 -41 47 -45 46 -33
-2 20 -16 18 -42 35 -58 38 -16 4 -32 13 -36 20 -5 8 -12 14 -18 14 -5 1 -9 6
-7 11 3 13 -1 13 -16 -2z"/>
<path d="M238 1143 l-85 -88 26 -21 26 -21 0 23 c0 13 -3 24 -7 24 -5 0 -8 -5
-8 -11 0 -5 -5 -7 -11 -4 -6 4 4 21 26 43 38 38 55 38 79 -1 9 -14 2 -25 -34
-62 l-45 -45 -32 31 c-41 37 -44 21 -4 -19 l29 -29 61 56 c34 31 61 60 61 64
0 5 -4 6 -8 3 -5 -3 -21 7 -36 23 -26 27 -26 28 -6 46 19 18 21 17 45 -9 31
-33 31 -39 4 -31 -21 7 -21 7 -3 -17 l18 -25 13 24 c11 22 9 27 -19 52 -30 27
-31 28 -13 54 9 15 15 27 13 27 -2 0 -43 -39 -90 -87z"/>
<path d="M2330 1223 c0 -1 16 -10 36 -18 30 -12 40 -13 52 -3 13 11 15 10 10
-4 -4 -9 -1 -19 7 -22 11 -4 12 0 5 17 -4 12 -13 21 -19 20 -6 -2 -11 2 -11 8
0 8 -4 8 -14 0 -9 -8 -16 -8 -20 -2 -5 8 -46 12 -46 4z"/>
<path d="M331 1188 c-13 -21 -13 -22 3 -9 20 17 34 7 17 -13 -9 -11 -8 -15 3
-19 19 -7 27 25 11 46 -16 22 -17 21 -34 -5z"/>
<path d="M2676 1195 c-3 -8 0 -14 6 -12 6 1 13 -4 16 -12 3 -10 -2 -12 -22 -7
-31 8 -32 4 -9 -20 13 -13 14 -19 5 -22 -7 -2 -9 -8 -6 -14 4 -6 -1 -8 -15 -3
-21 6 -30 -8 -11 -20 6 -3 10 -17 10 -30 0 -28 15 -41 24 -20 3 8 1 15 -4 15
-14 0 8 63 26 73 14 8 14 11 3 19 -11 8 -10 9 3 5 9 -3 24 3 33 13 15 17 15
19 -7 34 -28 20 -44 20 -52 1z"/>
<path d="M1976 1182 c-3 -5 8 -19 24 -31 17 -12 30 -28 30 -35 0 -7 9 -25 20
-38 16 -22 17 -28 6 -44 -16 -21 -2 -54 23 -54 10 0 12 9 8 35 -3 21 -2 33 4
30 19 -12 8 17 -16 40 -13 14 -20 25 -15 25 4 0 3 14 -3 30 -6 17 -15 30 -20
30 -5 0 -6 -7 -3 -16 6 -15 4 -15 -11 -3 -10 7 -23 19 -30 27 -6 7 -14 9 -17
4z"/>
<path d="M2453 1171 c-14 -12 -18 -22 -13 -32 5 -8 4 -19 -2 -26 -7 -9 -6 -14
6 -19 21 -8 10 -25 -12 -17 -9 3 -14 2 -9 -2 12 -13 77 -34 82 -26 2 4 -2 19
-9 33 -8 14 -12 37 -11 52 2 14 -1 24 -6 20 -5 -3 -9 -1 -9 5 0 5 5 11 12 13
7 3 8 7 2 10 -5 3 -19 -2 -31 -11z"/>
<path d="M2620 1161 c0 -22 -32 -41 -51 -30 -14 7 -19 2 -28 -22 -9 -27 -9
-30 4 -19 8 7 15 8 15 2 0 -19 -26 -32 -38 -20 -14 14 -16 -1 -3 -21 7 -11 13
-8 31 12 20 23 58 28 44 6 -3 -5 -9 -7 -14 -4 -7 4 -23 -31 -21 -47 2 -13 -27
-9 -32 5 -3 6 -6 1 -7 -13 -1 -14 -3 -33 -5 -43 -2 -11 1 -16 8 -14 7 2 11 14
10 26 -4 31 16 26 30 -8 10 -24 16 -27 35 -20 12 5 22 11 22 14 0 4 -4 4 -9 1
-4 -3 -16 0 -25 7 -16 12 -16 14 -2 26 9 8 20 11 23 7 4 -4 1 -11 -7 -16 -9
-6 -10 -10 -3 -10 6 0 15 5 18 10 5 8 11 7 21 -1 11 -9 14 -9 14 0 0 8 4 8 14
-1 8 -7 12 -16 9 -20 -2 -5 3 -8 11 -8 9 0 16 -5 16 -11 0 -5 4 -8 9 -4 5 3
12 1 16 -5 3 -5 11 -10 17 -10 6 0 1 11 -12 25 -13 14 -30 25 -37 25 -8 0 -16
7 -19 15 -4 10 -13 13 -26 9 -14 -4 -18 -2 -14 5 5 7 1 9 -9 5 -13 -5 -15 -1
-11 22 l6 29 -25 -30 c-27 -33 -34 -23 -9 13 8 12 25 23 37 23 17 1 18 2 4 6
-13 3 -16 12 -14 31 3 15 10 26 16 25 13 -4 14 7 1 27 -8 12 -10 12 -10 1z"/>
<path d="M1105 1140 c5 -25 -13 -26 -31 -3 -14 20 -29 12 -21 -12 4 -8 16 -15
27 -15 11 0 20 -5 20 -11 0 -5 -4 -7 -10 -4 -13 8 -13 -16 1 -35 9 -13 10 -13
6 2 -3 9 1 22 10 30 14 11 15 9 11 -19 -2 -18 -1 -34 3 -35 4 -2 10 -15 14
-29 5 -21 2 -27 -15 -32 -19 -5 -21 -2 -16 26 5 29 5 30 -5 10 -15 -29 4 -78
31 -80 25 -2 37 16 19 28 -12 8 -12 10 0 18 12 8 12 13 3 24 -10 12 -8 16 10
21 18 5 20 8 9 15 -7 5 -10 14 -7 20 4 6 2 11 -4 11 -6 0 -8 10 -4 26 5 18 1
30 -14 45 -27 25 -42 24 -37 -1z"/>
<path d="M2726 1121 c-37 -38 -46 -65 -23 -74 10 -4 10 -6 0 -6 -25 -2 -13
-30 26 -60 39 -31 51 -31 51 -1 0 21 71 90 92 90 28 0 29 16 0 41 -14 13 -21
17 -14 8 10 -13 8 -15 -17 -11 -26 4 -28 2 -23 -26 3 -23 -1 -33 -17 -42 -22
-11 -61 -5 -61 10 0 5 -7 16 -16 24 -13 13 -13 16 -1 16 8 0 20 7 27 15 7 9
20 15 29 13 14 -2 74 30 61 33 -56 12 -79 6 -114 -30z m34 -122 c0 -12 -27
-11 -35 1 -11 17 5 32 21 19 8 -6 14 -15 14 -20z"/>
<path d="M2400 1125 c-7 -8 -17 -12 -24 -8 -6 3 -2 -4 8 -16 19 -21 19 -23 3
-50 -22 -37 -22 -47 0 -30 9 8 14 9 10 3 -4 -6 3 -20 15 -30 21 -19 21 -19 9
4 -12 23 -8 42 10 42 5 0 9 -5 9 -11 0 -5 -5 -7 -11 -4 -6 4 -1 -11 10 -33 17
-34 18 -42 7 -52 -11 -8 -16 -8 -21 0 -4 6 -14 9 -22 5 -13 -4 -14 -3 -3 10 9
11 9 15 1 15 -7 0 -9 5 -5 12 4 7 3 8 -4 4 -13 -8 -43 5 -45 20 -1 5 -10 10
-20 11 -14 2 -17 -1 -12 -17 4 -11 2 -20 -4 -20 -6 0 -11 -5 -11 -10 0 -6 9
-5 21 2 17 11 23 11 26 0 3 -6 8 -10 13 -7 4 3 15 -6 23 -19 15 -23 15 -24 -9
-30 -34 -9 -20 -26 22 -26 22 0 33 4 32 13 -2 6 3 11 10 9 8 -1 11 2 8 7 -3 5
0 14 8 20 19 16 29 -25 10 -43 -8 -8 -14 -22 -14 -31 0 -13 9 -15 57 -11 59 5
78 19 63 47 -5 9 -6 19 -3 23 3 3 1 6 -5 6 -6 0 -14 -5 -17 -10 -9 -14 -27 -1
-19 13 5 8 3 8 -7 0 -18 -17 -29 -16 -29 2 0 8 -4 15 -10 15 -5 0 -10 9 -10
21 0 11 -4 17 -10 14 -5 -3 -10 -1 -10 5 0 7 7 10 15 7 20 -8 28 9 16 30 -9
16 -10 16 -11 2 0 -18 -30 -36 -30 -19 0 6 4 10 10 10 5 0 7 7 3 15 -3 9 -15
15 -26 13 -17 -2 -21 2 -22 22 0 14 3 35 7 48 9 26 5 28 -12 7z m148 -256
c-16 -11 -30 -7 -36 11 -3 9 1 11 12 7 11 -4 19 -1 24 11 6 16 7 15 10 -2 2
-10 -2 -22 -10 -27z"/>
<path d="M477 1124 c-12 -13 -8 -32 9 -38 13 -5 14 -4 6 7 -7 8 -9 17 -5 20
11 12 21 8 27 -10 4 -14 4 -14 3 2 -2 20 -27 32 -40 19z"/>
<path d="M710 1115 c-11 -14 -11 -18 -1 -25 8 -4 10 -13 6 -21 -5 -9 -2 -8 9
2 12 11 14 22 8 38 -7 21 -9 21 -22 6z"/>
<path d="M1021 1116 c-9 -10 -9 -16 -1 -21 7 -5 5 -17 -9 -40 l-19 -33 27 7
c24 7 44 41 23 41 -4 0 -7 14 -4 30 5 33 0 37 -17 16z"/>
<path d="M2140 1112 c0 -23 7 -26 34 -16 18 7 22 34 5 34 -5 0 -7 -4 -4 -10 9
-14 -8 -13 -23 2 -9 9 -12 7 -12 -10z"/>
<path d="M1960 1112 c0 -6 5 -14 10 -17 17 -11 11 -30 -21 -63 -25 -26 -35
-30 -46 -21 -8 7 -11 20 -7 33 6 18 5 19 -9 8 -9 -8 -18 -9 -21 -4 -9 14 21
35 40 28 10 -4 14 -13 11 -26 -6 -24 -3 -25 23 -3 24 21 26 35 3 26 -13 -5
-15 -2 -10 12 6 14 5 15 -6 7 -7 -6 -18 -7 -26 -2 -10 5 -20 1 -32 -13 -17
-21 -17 -23 13 -54 27 -28 68 -41 68 -21 0 4 8 8 18 8 15 0 15 2 2 11 -13 9
-13 10 0 6 8 -2 14 -12 14 -21 -1 -9 2 -16 7 -16 6 0 -6 -13 -24 -30 -20 -17
-46 -30 -61 -30 -23 0 -25 3 -20 28 6 28 2 34 -40 69 -11 10 -15 10 -19 0 -2
-6 5 -16 16 -22 41 -21 43 -41 9 -73 -17 -16 -34 -27 -37 -24 -3 3 -5 0 -5 -6
0 -13 19 -15 44 -6 9 3 13 10 10 15 -3 5 0 9 5 9 6 0 11 -4 11 -9 0 -5 7 -12
16 -15 14 -5 15 -4 4 9 -14 17 0 21 18 3 8 -8 15 -8 25 0 8 7 18 8 22 4 5 -4
5 -2 1 5 -9 16 13 27 49 25 23 -2 26 0 15 8 -11 7 -12 10 -2 10 9 0 7 10 -8
31 -20 27 -21 32 -6 47 18 21 9 73 -15 80 -12 5 -12 3 0 -11 17 -22 3 -22 -21
0 -10 9 -18 11 -18 5z"/>
<path d="M610 1103 c-9 -2 -21 -13 -29 -24 -7 -12 -8 -19 -3 -15 5 3 14 -6 20
-19 7 -14 16 -25 22 -25 7 0 4 13 -6 30 -20 33 -10 56 11 26 12 -16 35 -15 35
2 0 12 -34 28 -50 25z"/>
<path d="M2343 1090 c-5 -19 -6 -20 -20 -3 -14 17 -14 17 -8 -1 3 -11 0 -23
-7 -28 -9 -6 -6 -7 9 -3 15 4 25 0 32 -12 8 -14 10 -9 8 24 -2 47 -6 53 -14
23z"/>
<path d="M234 1088 c-4 -7 -9 -23 -10 -36 l-3 -24 25 23 c28 26 30 34 8 25
-12 -4 -15 -2 -10 9 7 18 0 20 -10 3z"/>
<path d="M2860 1024 c-36 -34 -62 -66 -57 -70 4 -4 26 10 48 32 42 40 84 93
78 98 -2 1 -33 -26 -69 -60z"/>
<path d="M682 1053 c-7 -10 -10 -12 -7 -6 4 8 1 11 -9 7 -16 -6 -12 -29 12
-56 16 -19 10 -63 -14 -89 -13 -15 -11 -19 21 -37 28 -17 35 -18 35 -7 0 8 -4
15 -8 15 -4 0 -8 21 -7 46 0 36 5 50 24 65 13 11 21 22 17 26 -3 3 -14 -1 -24
-10 -28 -25 -33 -21 -26 23 7 44 5 48 -14 23z"/>
<path d="M1044 1014 c3 -8 2 -12 -4 -9 -19 12 -10 -21 12 -39 19 -16 20 -17
15 -1 -4 9 -7 28 -7 41 0 13 -5 24 -11 24 -6 0 -8 -7 -5 -16z"/>
<path d="M1257 1023 c-2 -5 -4 -20 -5 -35 -2 -25 0 -26 36 -21 40 6 68 23 38
23 -24 0 -51 26 -33 33 6 3 2 6 -10 6 -12 1 -24 -2 -26 -6z"/>
<path d="M112 978 c-16 -16 -15 -33 2 -47 10 -9 15 -8 20 4 3 9 0 15 -9 15 -8
0 -15 5 -15 10 0 16 33 26 38 12 2 -7 8 -10 13 -6 14 8 1 24 -20 24 -9 0 -22
-5 -29 -12z"/>
<path d="M1802 978 c-14 -19 4 -66 20 -50 9 9 7 15 -8 26 -18 14 -18 15 0 22
11 4 21 2 26 -7 5 -7 12 -10 15 -7 11 11 -5 28 -24 28 -10 0 -23 -6 -29 -12z"/>
<path d="M737 960 c-10 -12 -15 -25 -12 -30 10 -17 25 -11 25 10 0 14 5 18 18
13 15 -6 15 -5 3 10 -16 21 -13 21 -34 -3z"/>
<path d="M1011 928 c-9 -30 -15 -37 -21 -27 -6 9 -12 10 -19 4 -14 -14 13 -47
32 -39 9 3 13 12 10 21 -3 8 0 19 7 23 8 5 14 18 15 30 3 37 -12 30 -24 -12z"/>
<path d="M1308 952 c-19 -2 -28 -9 -28 -22 0 -10 -5 -22 -10 -25 -17 -10 0
-26 20 -20 17 5 19 2 15 -29 -3 -20 -1 -36 4 -36 5 0 8 11 6 25 -1 14 4 33 11
42 13 17 14 17 8 1 -4 -11 0 -20 10 -24 9 -3 16 -14 17 -23 0 -9 3 -11 5 -4 3
7 12 9 20 6 23 -8 18 45 -6 59 -11 7 -20 18 -20 24 0 19 -23 30 -52 26z"/>
<path d="M1397 936 c-3 -8 1 -22 8 -33 8 -10 15 -33 15 -50 0 -28 -4 -33 -22
-33 -18 0 -20 -2 -9 -9 8 -5 21 -7 29 -4 11 5 13 19 8 72 -3 36 -6 67 -6 69 0
8 -19 -1 -23 -12z"/>
<path d="M2640 942 c-8 -3 -20 -12 -27 -21 -9 -11 -12 -12 -13 -3 0 18 -20 4
-20 -14 0 -8 5 -12 10 -9 6 3 10 1 10 -4 0 -17 27 -13 34 4 8 22 36 18 36 -5
0 -11 -7 -20 -15 -20 -12 0 -13 -4 -5 -19 14 -26 13 -31 -5 -31 -9 0 -15 7
-13 16 2 10 -2 19 -7 21 -6 2 -12 -9 -13 -24 -1 -16 -7 -29 -12 -31 -16 -6
-12 -22 5 -22 8 0 15 7 15 16 0 25 23 11 31 -19 6 -24 5 -26 -11 -17 -25 14
-30 13 -24 -5 7 -18 -12 -20 -23 -2 -4 6 -19 18 -32 25 -17 10 -21 10 -16 1 5
-7 4 -10 -1 -6 -6 3 -24 -1 -42 -10 -28 -13 -32 -21 -32 -54 0 -22 4 -39 10
-39 5 0 7 7 4 15 -4 8 -1 15 5 15 6 0 11 5 11 11 0 5 -4 7 -10 4 -14 -8 -12 7
3 25 12 15 67 27 67 15 0 -4 -5 -15 -10 -26 -9 -15 -7 -19 5 -19 9 0 13 4 10
10 -3 5 -1 18 4 27 11 19 11 19 15 -36 1 -8 7 -16 14 -19 8 -2 12 3 10 13 -4
24 19 31 25 8 4 -14 5 -13 6 5 1 17 7 22 26 22 14 0 34 9 46 21 20 20 20 21 3
40 -10 11 -24 17 -31 15 -9 -4 -13 3 -13 19 0 14 4 25 8 25 13 0 31 45 24 56
-12 16 -47 31 -62 26z m70 -157 c0 -16 -27 -32 -37 -22 -3 4 -3 13 0 22 8 20
37 20 37 0z"/>
<path d="M2050 925 c-7 -8 -16 -13 -20 -10 -5 3 -25 -6 -45 -19 -19 -14 -41
-27 -48 -30 -9 -3 -8 -7 3 -15 10 -7 11 -11 3 -11 -7 0 -13 -7 -13 -16 0 -10
-6 -14 -15 -11 -8 4 -17 2 -20 -3 -3 -5 -12 -7 -19 -4 -18 7 -57 -27 -59 -53
0 -12 -5 -19 -9 -17 -5 3 -5 10 -2 16 4 6 1 8 -7 6 -8 -3 -13 -11 -12 -17 2
-6 -1 -11 -6 -11 -5 0 -17 -7 -26 -16 -12 -13 -18 -14 -31 -4 -8 7 -18 9 -22
5 -4 -4 -1 -10 6 -12 6 -3 10 -9 6 -14 -8 -14 19 -10 40 6 11 8 27 15 37 15
21 0 40 22 42 49 0 11 5 17 11 14 5 -3 6 -1 2 5 -4 7 -2 12 3 12 14 0 14 -13
-1 -28 -6 -6 -9 -14 -6 -17 7 -7 63 22 78 40 7 8 18 15 24 15 15 0 86 74 86
89 0 7 4 10 9 6 6 -3 15 4 21 16 14 25 8 35 -10 14z"/>
<path d="M1764 910 c-25 -10 -41 -30 -25 -30 6 0 11 5 11 12 0 6 3 8 7 5 3 -4
-2 -17 -13 -29 l-19 -22 34 20 c19 10 35 27 37 37 3 19 -1 20 -32 7z"/>
<path d="M765 896 c-30 -34 -36 -46 -30 -56 5 -8 11 -8 21 1 7 6 11 15 8 21
-3 5 0 16 7 24 14 17 9 27 -6 10z"/>
<path d="M630 892 c0 -22 13 -42 27 -42 8 0 11 -4 8 -10 -3 -5 1 -10 9 -10 8
0 22 -7 30 -16 15 -14 16 -14 16 0 0 9 -4 16 -8 16 -5 0 -25 17 -45 37 -20 20
-37 31 -37 25z"/>
<path d="M1701 883 c-17 -7 -42 -33 -58 -61 -14 -24 -18 -50 -5 -38 4 4 7 12
7 17 0 5 5 9 10 10 16 1 35 20 51 51 8 15 13 28 12 27 -2 0 -9 -3 -17 -6z"/>
<path d="M2581 858 c-6 -16 -15 -28 -21 -28 -16 0 -12 -26 7 -41 11 -10 14
-10 9 -1 -4 6 -3 12 2 12 6 0 8 9 5 19 -3 12 1 21 10 23 10 2 12 9 7 23 -7 19
-9 18 -19 -7z"/>
<path d="M562 863 c-6 -3 -10 -9 -6 -14 3 -5 -3 -9 -12 -9 -17 0 -16 -1 1 -13
10 -8 15 -19 11 -25 -8 -13 28 -25 53 -17 29 9 32 41 7 62 -25 20 -35 23 -54
16z m28 -23 c0 -5 7 -10 15 -10 20 0 19 -19 0 -27 -19 -7 -20 -5 -29 25 -5 13
-3 22 3 22 6 0 11 -4 11 -10z"/>
<path d="M779 863 c-3 -25 -4 -28 -13 -41 -4 -7 -14 -11 -22 -8 -17 7 -18 -16
-2 -32 10 -10 7 -17 -12 -35 -27 -24 -70 -34 -70 -16 0 8 -5 7 -15 -1 -12 -10
-15 -10 -15 0 0 10 -3 10 -15 0 -19 -16 -10 -30 15 -23 14 4 20 0 20 -11 0 -9
-5 -16 -12 -16 -9 0 -9 -3 -1 -11 16 -16 35 -1 28 22 -4 10 -3 19 2 20 4 0 17
2 28 3 11 2 42 24 69 51 l48 48 -15 31 c-9 17 -17 25 -18 19z"/>
<path d="M2380 860 c0 -6 -4 -8 -10 -5 -5 3 -10 -1 -10 -10 0 -10 -5 -13 -12
-9 -7 5 -8 3 -2 -7 5 -8 7 -22 6 -31 -4 -20 3 -21 33 -8 20 9 20 9 0 9 -16 1
-17 3 -5 11 8 5 11 12 7 16 -4 4 -2 13 4 20 7 8 7 14 -1 19 -5 3 -10 1 -10 -5z"/>
<path d="M1786 852 c-4 -8 2 -16 19 -22 32 -12 45 -13 45 -1 0 5 15 12 33 16
l32 8 -28 4 c-16 3 -41 0 -57 -6 -21 -7 -30 -6 -33 2 -2 8 -5 8 -11 -1z"/>
<path d="M2137 844 c-3 -3 -3 -12 -1 -19 3 -7 -2 -15 -11 -19 -9 -3 -14 -10
-11 -15 3 -5 -1 -11 -9 -15 -8 -3 -15 -12 -15 -21 0 -8 -3 -15 -8 -15 -4 0
-19 -11 -32 -23 -40 -37 -128 -97 -143 -97 -7 0 -22 -7 -33 -15 -30 -20 -75
-26 -92 -12 -12 9 -16 9 -19 0 -3 -7 -14 -14 -26 -14 -17 -1 -18 -2 -4 -6 27
-7 20 -20 -14 -27 -18 -3 -41 0 -58 9 -16 8 -31 11 -35 8 -3 -4 -6 -2 -6 3 0
5 -9 10 -20 10 -19 0 -19 -1 2 -23 12 -13 17 -23 10 -23 -6 0 -19 11 -30 25
l-20 25 -11 -31 c-6 -18 -11 -34 -11 -37 0 -5 109 4 188 14 37 6 42 9 43 33 2
23 2 24 6 4 2 -13 8 -23 12 -23 27 0 141 48 193 82 65 42 188 161 188 181 -1
17 -26 48 -33 41z"/>
<path d="M2500 829 c0 -11 -4 -17 -10 -14 -5 3 -10 1 -10 -5 0 -6 -4 -8 -10
-5 -15 10 -12 23 8 28 13 4 13 5 -4 6 -14 1 -24 -6 -27 -19 -3 -11 -1 -20 5
-20 6 0 7 -7 4 -16 -3 -9 11 -2 34 16 45 36 45 36 25 44 -10 4 -15 -1 -15 -15z"/>
<path d="M817 809 c-3 -10 -1 -22 5 -25 5 -3 7 -12 4 -20 -3 -8 0 -11 10 -7
16 6 20 -32 4 -42 -5 -3 -10 -14 -9 -23 0 -9 3 -12 5 -6 3 7 13 10 23 7 9 -2
23 3 31 12 11 14 11 16 0 13 -8 -2 -15 4 -16 12 -3 37 -5 41 -13 35 -15 -8
-32 30 -20 42 7 7 6 12 -4 16 -9 3 -17 -2 -20 -14z"/>
<path d="M1735 812 c-42 -14 -61 -30 -49 -42 13 -13 58 10 68 36 3 8 4 14 3
13 -1 -1 -11 -4 -22 -7z"/>
<path d="M1298 803 c-16 -2 -28 -9 -28 -14 0 -6 4 -8 9 -5 5 3 12 -1 15 -9 3
-8 9 -14 13 -13 4 1 18 -3 31 -10 12 -7 22 -10 22 -7 0 3 10 -3 22 -15 22 -20
98 -29 98 -11 0 15 -32 22 -46 10 -11 -9 -19 -8 -37 7 -12 11 -25 24 -29 29
-5 6 -8 4 -8 -4 0 -17 -15 -5 -15 12 0 7 -8 11 -18 11 -14 -2 -15 1 -6 12 6 8
10 13 8 13 -2 -1 -16 -4 -31 -6z"/>
<path d="M2270 789 c0 -7 -10 -9 -24 -5 -15 4 -28 1 -32 -6 -5 -7 -2 -9 8 -5
10 3 19 -3 27 -19 15 -34 26 -30 28 11 2 19 1 35 -2 35 -3 0 -5 -5 -5 -11z"/>
<path d="M2188 768 c-4 -10 -13 -16 -20 -14 -7 1 -10 1 -5 -1 4 -3 7 -12 7
-21 0 -15 1 -15 16 0 9 8 14 24 12 34 -3 18 -4 18 -10 2z"/>
<path d="M2391 756 c11 -24 11 -29 -4 -37 -9 -6 -17 -18 -17 -27 1 -13 7 -10
26 12 14 16 23 35 19 44 -4 11 -2 12 8 4 10 -9 14 -8 14 2 0 8 -7 13 -16 11
-9 -1 -22 2 -29 8 -11 8 -11 5 -1 -17z"/>
<path d="M773 719 c0 -24 -4 -53 -8 -64 -8 -18 -7 -19 8 -6 9 7 17 21 17 30 0
9 5 22 12 29 9 9 9 12 0 12 -10 0 -10 5 -2 20 7 14 7 20 0 20 -5 0 -10 -6 -10
-12 0 -10 -3 -9 -9 1 -6 9 -9 -1 -8 -30z"/>
<path d="M2321 751 c-8 -5 -11 -16 -8 -24 3 -9 1 -18 -6 -20 -7 -3 -2 -4 11
-4 23 1 65 37 43 37 -6 0 -14 -7 -17 -15 -4 -8 -11 -12 -16 -9 -6 4 -1 14 13
25 25 19 8 27 -20 10z"/>
<path d="M1527 708 c3 -17 2 -19 -5 -10 -7 11 -10 10 -16 -3 -9 -24 -8 -35 4
-35 5 0 7 6 4 14 -3 9 1 13 12 13 10 -1 18 7 20 21 3 14 -1 22 -10 22 -9 0
-12 -8 -9 -22z"/>
<path d="M2444 723 c4 -8 1 -13 -8 -13 -19 0 -26 -20 -14 -43 5 -10 7 -21 4
-25 -3 -3 -6 0 -6 6 0 7 -5 12 -11 12 -5 0 -8 -4 -5 -8 3 -5 -2 -9 -10 -9 -9
0 -13 6 -9 16 3 9 -10 0 -28 -19 -19 -19 -33 -37 -30 -40 3 -3 13 5 23 17 17
21 18 21 34 4 9 -10 13 -21 9 -26 -5 -4 -2 -5 5 -1 6 4 12 14 12 22 0 8 5 14
11 14 17 0 41 28 27 32 -8 3 -8 10 1 27 10 18 10 25 0 35 -9 8 -10 8 -5 -1z"/>
<path d="M1635 703 c9 -32 -15 -40 -37 -12 -19 24 -19 24 -8 1 13 -27 42 -41
48 -23 3 9 7 9 18 0 9 -7 14 -8 14 -1 0 6 -6 13 -12 15 -10 4 -10 8 1 15 9 7
10 12 2 16 -6 4 -11 1 -11 -6 0 -10 -3 -9 -11 2 -8 12 -9 10 -4 -7z"/>
<path d="M2183 694 c-15 -13 -31 -24 -35 -24 -5 0 -8 -7 -8 -15 0 -14 -10 -19
-26 -12 -13 4 -23 -43 -15 -66 6 -15 4 -26 -6 -35 -12 -12 -16 -9 -26 18 -11
29 -15 31 -46 26 -36 -6 -70 -31 -58 -43 4 -3 13 2 20 11 12 16 13 15 14 -15
0 -19 -5 -37 -10 -41 -6 -3 2 -5 16 -5 27 2 37 22 16 29 -8 3 -8 10 2 26 17
28 29 29 22 2 -4 -16 -2 -19 11 -14 11 4 16 1 16 -12 0 -14 3 -15 12 -6 17 17
45 15 52 -4 6 -16 26 -11 26 7 0 6 -3 8 -7 6 -5 -3 -14 1 -21 9 -7 8 -9 14 -5
14 4 0 2 5 -5 12 -21 21 -13 29 13 12 14 -9 25 -12 25 -6 0 6 -4 14 -10 17 -5
3 -10 11 -10 16 0 6 4 8 9 5 5 -4 7 3 4 14 -3 11 0 20 6 20 6 0 11 5 11 11 0
5 -5 7 -12 3 -7 -4 -8 -3 -4 4 9 15 36 15 36 0 0 -18 13 0 15 20 2 26 20 30
23 5 2 -13 9 -23 15 -23 7 0 5 -5 -5 -10 -10 -6 -14 -18 -11 -31 4 -12 0 -25
-8 -30 -12 -8 -12 -11 1 -20 11 -8 12 -12 3 -16 -7 -2 -13 -9 -13 -14 0 -5 9
-4 19 3 14 9 17 16 10 24 -16 20 6 53 31 47 15 -4 18 -9 10 -18 -8 -9 -6 -14
5 -19 9 -3 13 -11 9 -17 -4 -8 -3 -9 5 -5 9 6 9 11 0 22 -9 11 -9 17 0 28 10
12 8 15 -8 18 -20 3 -20 5 -6 32 14 27 14 31 0 45 -13 14 -15 13 -15 -5 0 -11
-6 -21 -14 -21 -8 0 -15 3 -14 6 4 38 -24 46 -59 15z"/>
<path d="M586 691 c12 -9 24 -26 26 -37 2 -13 10 -18 23 -16 18 4 18 1 -5 -24
-14 -15 -20 -24 -14 -21 6 3 22 -5 37 -19 20 -19 27 -21 27 -9 0 8 7 15 15 15
8 0 15 -5 15 -12 0 -6 3 -8 7 -5 3 4 -4 19 -18 33 l-24 26 30 -6 c17 -3 23 -3
15 0 -8 4 -22 13 -31 21 -9 7 -21 13 -26 11 -6 -2 -27 12 -46 30 -20 17 -40
32 -44 32 -5 -1 2 -9 13 -19z m88 -73 c-8 -29 -10 -31 -23 -23 -8 4 -8 12 -1
26 15 27 33 24 24 -3z"/>
<path d="M421 658 c-1 -16 2 -29 7 -29 46 -8 50 -6 40 17 -6 13 -16 24 -21 24
-5 0 -4 -6 2 -14 8 -10 9 -16 1 -21 -5 -3 -12 0 -14 7 -12 39 -14 41 -15 16z"/>
<path d="M799 648 c-1 -7 -2 -17 -4 -23 -1 -5 -3 -16 -3 -24 -2 -11 -6 -11
-21 3 -11 10 -22 13 -25 8 -3 -5 3 -12 14 -15 11 -3 20 -12 20 -21 0 -8 6 -13
13 -10 12 4 23 94 12 94 -3 0 -6 -6 -6 -12z"/>
<path d="M860 649 c0 -5 -4 -7 -10 -4 -16 10 -12 -5 9 -30 14 -19 21 -21 31
-12 10 9 14 9 17 0 3 -7 11 -13 18 -13 19 0 28 -17 14 -26 -8 -5 -10 -2 -5 7
4 8 0 5 -9 -6 -15 -19 -17 -19 -26 -2 -16 27 -30 20 -24 -14 6 -33 25 -69 37
-69 11 0 10 18 -2 25 -5 3 -10 13 -10 21 0 9 5 12 11 8 6 -3 15 -1 19 6 4 7
13 9 20 5 9 -6 7 -12 -8 -24 -18 -14 -19 -18 -7 -35 7 -10 19 -15 25 -11 8 5
8 11 -1 21 -9 11 -8 14 4 14 22 0 31 18 17 35 -6 8 -9 19 -5 25 3 5 -2 13 -12
17 -21 8 -48 33 -36 33 4 0 -5 9 -22 20 -34 22 -45 25 -45 9z"/>
<path d="M2500 639 c0 -6 -6 -9 -14 -6 -11 4 -13 -1 -10 -19 2 -13 0 -24 -6
-24 -5 0 -10 7 -10 15 0 8 -9 15 -20 15 -13 0 -18 -5 -14 -15 4 -10 -3 -18
-21 -25 -33 -13 -56 -13 -36 -1 8 5 10 14 6 22 -6 9 -10 7 -16 -9 -6 -15 -14
-20 -26 -16 -14 5 -15 4 -4 -4 11 -8 10 -12 -5 -21 -13 -7 -14 -10 -5 -11 8 0
20 8 27 18 13 16 13 16 14 -3 0 -12 3 -16 8 -9 4 6 18 15 31 19 20 6 23 4 18
-9 -4 -11 -3 -15 4 -11 6 4 8 12 6 19 -3 6 2 17 9 24 11 10 14 10 14 -2 0 -9
7 -13 23 -9 17 4 15 1 -9 -15 -18 -11 -35 -25 -39 -31 -3 -6 -10 -9 -15 -6 -5
3 -11 1 -14 -4 -6 -10 11 -25 29 -26 6 -1 9 -7 7 -14 -2 -8 3 -11 12 -8 9 4
18 2 21 -3 4 -6 12 -10 18 -10 7 0 -3 10 -22 22 -30 19 -32 23 -15 29 10 3 21
2 22 -2 2 -5 9 -9 15 -9 5 0 7 3 3 7 -3 4 4 13 16 21 l23 13 -22 -4 c-13 -3
-26 -1 -29 4 -3 5 -1 9 5 9 14 0 81 59 81 72 0 5 -11 -2 -23 -16 -28 -30 -38
-25 -30 15 3 16 3 29 -1 29 -3 0 -6 -5 -6 -11z"/>
<path d="M434 535 c-4 -9 -1 -27 6 -40 7 -13 9 -29 5 -37 -4 -7 4 1 19 17 14
17 25 38 26 48 0 19 15 23 23 7 3 -6 4 -5 3 2 -4 20 -75 23 -82 3z"/>
<path d="M1070 537 c0 -11 -5 -16 -12 -12 -7 4 3 -7 22 -24 33 -30 70 -41 70
-21 0 6 6 10 14 10 8 0 16 7 20 15 3 9 0 15 -9 15 -8 0 -15 5 -15 10 0 12 -22
9 -32 -5 -4 -5 -1 -16 5 -25 11 -13 10 -13 -8 0 -11 8 -28 23 -37 35 -16 18
-18 18 -18 2z"/>
<path d="M1914 542 c-13 -9 -13 -50 1 -48 5 1 16 5 23 9 7 5 12 4 12 -2 0 -6
5 -11 11 -11 5 0 7 5 4 10 -3 6 1 10 10 10 9 0 13 5 10 10 -10 16 -43 12 -54
-7 -7 -13 -10 -14 -11 -4 0 8 8 20 18 27 14 11 14 13 2 14 -8 0 -20 -4 -26 -8z"/>
<path d="M1880 530 c0 -5 -7 -10 -15 -10 -8 0 -15 -3 -15 -6 0 -12 34 -64 42
-64 11 0 10 18 -1 25 -5 3 -8 19 -6 35 2 17 2 30 0 30 -3 0 -5 -4 -5 -10z"/>
<path d="M1205 520 c-10 -16 -1 -22 32 -23 26 -2 28 -4 19 -24 -9 -17 -12 -19
-19 -7 -11 20 -26 17 -20 -4 2 -10 -3 -24 -11 -31 -17 -14 -22 -41 -7 -41 5 0
16 11 25 25 9 14 23 25 30 25 16 0 66 38 66 49 0 5 -19 11 -42 15 -23 4 -47
11 -54 17 -8 7 -14 6 -19 -1z"/>
<path d="M606 501 c-10 -11 -24 -17 -31 -15 -7 3 -28 -18 -49 -48 -20 -29 -26
-41 -13 -26 14 15 28 24 32 20 4 -4 6 -2 5 3 -5 21 0 33 15 39 10 4 15 -1 15
-15 0 -11 -4 -17 -10 -14 -5 3 -10 3 -10 -2 0 -4 17 -13 37 -20 30 -9 39 -8
51 3 18 19 8 31 -12 14 -13 -10 -18 -9 -31 3 -8 9 -11 18 -6 21 5 4 11 1 13
-6 3 -7 12 -9 22 -5 9 4 14 11 11 17 -4 6 -10 8 -15 5 -4 -3 -7 6 -5 20 3 30
3 30 -19 6z"/>
<path d="M2234 512 c-19 -12 -25 -59 -10 -71 10 -8 16 -9 21 -1 3 6 11 7 17 4
7 -5 8 -2 4 5 -5 7 -14 10 -22 7 -7 -3 -16 -1 -19 4 -4 6 -1 10 5 10 6 0 8 5
5 10 -3 6 1 10 9 10 9 0 16 4 16 9 0 14 -14 21 -26 13z"/>
<path d="M500 477 c-14 -17 -18 -27 -10 -23 25 15 50 39 50 48 0 16 -15 7 -40
-25z"/>
<path d="M840 488 c0 -25 17 -48 34 -48 6 0 5 5 -2 12 -7 7 -12 20 -12 29 0
10 -4 21 -10 24 -6 3 -10 -4 -10 -17z"/>
<path d="M1705 484 c15 -11 17 -17 7 -23 -9 -5 -5 -13 13 -29 29 -26 42 -28
42 -7 0 8 -4 12 -9 9 -4 -3 -8 2 -8 11 0 11 7 14 23 11 22 -4 40 9 45 33 4 17
-58 7 -69 -11 -6 -9 -10 -8 -15 5 -3 9 -16 17 -28 17 -21 -1 -21 -1 -1 -16z"/>
<path d="M1535 479 c-3 -4 -2 -14 1 -23 3 -9 1 -16 -4 -16 -6 0 -13 9 -17 19
-11 35 -75 1 -74 -39 0 -10 2 -11 6 -2 2 6 9 12 15 12 6 0 8 -7 5 -15 -4 -8
-2 -17 4 -20 5 -4 12 0 15 9 3 8 28 20 56 26 42 9 55 8 75 -5 l24 -16 -16 24
c-11 18 -22 23 -40 20 -19 -4 -23 -2 -19 14 4 14 0 20 -11 20 -9 0 -18 -4 -20
-8z"/>
<path d="M807 467 c4 -6 7 -19 5 -27 -4 -26 14 -43 37 -35 28 8 27 22 -1 21
-18 -1 -22 4 -21 21 1 11 -4 24 -13 27 -10 4 -13 2 -7 -7z"/>
<path d="M1018 468 c-7 -17 7 -19 29 -5 10 6 11 0 7 -23 -5 -23 -3 -30 10 -30
18 0 22 16 4 22 -15 5 -1 28 17 28 8 0 23 -10 35 -22 11 -12 20 -16 20 -10 0
7 5 12 11 12 5 0 7 -4 4 -10 -3 -5 3 -10 14 -10 12 0 21 5 21 10 0 6 -5 10
-10 10 -7 0 -7 7 0 21 11 20 11 20 -19 5 -26 -14 -33 -14 -66 0 -27 11 -40 12
-50 4 -10 -8 -14 -8 -17 2 -3 8 -6 7 -10 -4z"/>
<path d="M2318 448 c-37 -18 -53 -33 -23 -22 8 4 17 -1 21 -10 5 -12 10 -14
19 -6 6 5 15 7 18 3 4 -3 7 -1 7 6 0 8 -7 10 -20 6 -26 -8 -26 6 -1 22 15 10
21 10 24 0 4 -9 6 -9 6 0 2 20 -12 20 -51 1z"/>
<path d="M1296 452 c-2 -4 -1 -14 3 -21 7 -10 13 -11 30 -2 12 7 19 16 16 22
-7 11 -42 12 -49 1z"/>
<path d="M1367 433 c4 -11 3 -14 -5 -9 -7 4 -12 3 -12 -2 0 -5 -10 -12 -22
-15 -16 -5 -19 -9 -10 -19 11 -14 12 -17 12 -45 0 -14 2 -15 9 -5 4 7 6 24 3
38 -3 16 0 24 9 24 8 0 25 12 38 25 l23 25 -26 0 c-20 0 -24 -4 -19 -17z"/>
<path d="M2662 435 c-15 -18 0 -55 22 -55 25 0 39 26 26 49 -13 24 -30 26 -48
6z m46 -22 c2 -8 -5 -13 -17 -13 -12 0 -21 6 -21 16 0 18 31 15 38 -3z"/>
<path d="M1655 417 c-9 -13 -21 -18 -34 -14 -25 6 -33 -3 -20 -23 5 -8 9 -10
9 -4 0 6 19 10 44 10 24 -1 42 2 40 6 -2 4 0 9 5 13 14 8 3 32 -15 32 -8 0
-21 -9 -29 -20z"/>
<path d="M2238 418 c-18 -6 -28 -17 -28 -29 0 -10 -4 -19 -10 -19 -5 0 -10 -5
-10 -12 0 -6 3 -8 6 -5 4 4 20 -3 36 -14 16 -12 32 -19 35 -15 3 3 -2 8 -11
12 -9 3 -16 13 -16 21 0 8 -4 11 -10 8 -5 -3 -10 -2 -10 2 0 5 18 8 39 8 22 0
38 -3 35 -6 -2 -4 4 -9 14 -11 11 -3 19 0 19 8 0 7 -5 12 -11 11 -6 -1 -10 4
-8 11 2 9 -4 12 -17 7 -14 -4 -20 -1 -23 13 -2 16 -7 17 -30 10z"/>
<path d="M1115 401 c-3 -5 5 -7 16 -4 16 4 20 2 17 -8 -3 -8 -13 -13 -22 -13
-18 2 -22 -11 -6 -21 17 -10 11 -21 -16 -34 -18 -8 -24 -17 -20 -27 4 -11 2
-15 -8 -11 -9 4 -17 -2 -21 -13 -3 -11 -17 -28 -30 -38 l-24 -19 27 -26 27
-26 0 24 c0 19 -4 23 -17 19 -10 -3 -18 -1 -18 5 0 13 37 24 50 16 6 -4 8 -16
4 -28 -6 -21 -6 -21 20 2 27 26 37 53 11 31 -9 -7 -19 -9 -23 -5 -4 4 -1 10 6
12 6 3 12 9 12 15 0 6 -6 5 -15 -2 -9 -7 -19 -10 -23 -6 -4 4 2 14 12 22 16
11 23 11 35 1 14 -10 14 -10 4 4 -11 13 -10 19 5 33 18 15 20 15 45 -8 32 -30
34 -40 7 -31 -15 5 -18 3 -14 -9 3 -9 1 -16 -5 -16 -6 0 -11 -7 -11 -15 0 -8
-8 -19 -19 -24 -10 -6 -22 -18 -25 -28 -6 -15 -4 -14 16 3 12 10 37 34 54 52
18 18 42 34 53 35 14 1 17 5 11 11 -14 14 -12 26 5 26 8 0 15 5 15 10 0 6 9
10 20 10 11 0 20 3 21 8 5 49 2 81 -6 78 -5 -2 -9 -18 -7 -36 2 -31 1 -32 -25
-25 -16 3 -29 11 -31 16 -2 5 -9 9 -15 9 -7 0 -17 8 -23 18 -12 19 -59 28 -69
13z m72 -50 c-4 -15 -1 -18 12 -14 9 4 13 3 9 -2 -4 -4 -3 -14 2 -20 7 -9 6
-16 -3 -23 -11 -9 -20 -5 -42 18 -16 16 -27 31 -24 32 53 33 53 33 46 9z"/>
<path d="M2824 365 c-15 -14 -30 -25 -35 -25 -4 0 -11 -9 -14 -21 -4 -11 -20
-25 -35 -31 -19 -6 -27 -15 -23 -24 4 -10 -3 -14 -22 -14 -23 0 -26 -2 -15
-15 10 -12 10 -15 0 -15 -8 0 -10 -3 -7 -7 4 -3 18 4 32 17 20 19 27 21 40 10
10 -8 13 -20 9 -33 -6 -18 -5 -19 7 -6 8 8 21 23 29 33 14 18 13 18 -10 11
-23 -7 -24 -7 -10 11 14 18 14 18 -3 5 -9 -8 -20 -11 -24 -8 -7 8 15 37 28 37
11 0 49 -37 49 -48 0 -4 -20 -28 -45 -52 l-45 -44 -16 22 c-8 12 -22 22 -30
22 -23 0 -16 -14 16 -33 35 -21 48 -16 104 43 l39 41 -31 32 c-25 26 -29 36
-20 45 16 16 28 15 50 -5 18 -16 18 -16 12 0 -3 9 -10 17 -15 17 -14 0 -10 22
8 42 24 27 8 22 -23 -7z"/>
<path d="M645 358 c-11 -6 -22 -12 -24 -14 -2 -2 4 -15 13 -29 9 -14 14 -30
11 -35 -3 -6 0 -10 8 -10 13 0 54 68 45 74 -1 1 -10 7 -18 13 -11 8 -21 8 -35
1z"/>
<path d="M1970 338 c0 -4 5 -8 10 -8 17 0 23 -4 59 -40 19 -19 27 -26 18 -14
-10 11 -14 24 -10 29 5 4 2 5 -4 1 -12 -7 -18 5 -14 27 1 5 -3 6 -9 2 -5 -3
-10 -2 -10 2 0 5 -9 9 -20 9 -11 0 -20 -4 -20 -8z"/>
<path d="M1355 313 c6 -25 24 -47 32 -39 4 4 -28 66 -34 66 -3 0 -2 -12 2 -27z"/>
<path d="M154 297 c-30 -31 -49 -57 -42 -57 2 0 24 20 48 45 24 25 40 45 35
45 -6 0 -24 -15 -41 -33z"/>
<path d="M1845 320 c-4 -6 -2 -17 4 -23 8 -8 17 -5 34 13 13 14 15 18 4 10
-15 -11 -20 -11 -27 -1 -6 10 -9 10 -15 1z"/>
<path d="M183 278 c-36 -38 -41 -47 -35 -73 10 -44 56 -99 76 -92 9 4 16 15
16 25 0 22 60 82 82 82 32 0 30 23 -6 58 -38 38 -51 32 -15 -7 12 -12 18 -25
15 -28 -3 -4 -22 12 -42 35 -19 23 -39 42 -43 42 -4 0 -25 -19 -48 -42z m87
-29 c9 -16 8 -26 -5 -45 -26 -39 -75 -26 -75 21 0 45 60 63 80 24z"/>
<path d="M1884 278 c-32 -34 -40 -50 -39 -75 3 -61 95 -124 95 -65 0 22 60 82
82 82 30 0 30 19 -1 52 -23 24 -31 28 -31 16 0 -9 8 -19 18 -21 9 -3 6 -4 -8
-3 -32 2 -52 15 -44 28 6 9 -12 27 -26 28 -3 0 -24 -19 -46 -42z m74 -20 c27
-27 7 -78 -29 -78 -20 0 -51 38 -43 52 3 4 0 8 -5 8 -6 0 -11 5 -11 11 0 6 7
9 15 6 8 -4 17 -2 20 3 9 14 39 12 53 -2z"/>
<path d="M1292 303 c-12 -2 -20 -9 -17 -14 3 -5 -1 -6 -9 -3 -9 3 -16 1 -16
-6 0 -7 11 -10 28 -9 15 2 34 -1 42 -6 8 -5 22 -10 30 -12 12 -1 11 1 -2 11
-10 7 -18 21 -18 30 0 9 -3 15 -7 15 -5 -1 -18 -4 -31 -6z"/>
<path d="M2830 280 c0 -3 5 -12 10 -20 8 -13 12 -12 29 6 19 21 19 21 -10 20
-16 -1 -29 -3 -29 -6z"/>
<path d="M1805 270 c-1 -3 0 -12 4 -22 5 -14 8 -13 23 7 17 23 17 24 -3 22
-11 -1 -22 -4 -24 -7z"/>
<path d="M2288 262 c4 -11 1 -13 -7 -7 -10 5 -11 2 -6 -14 4 -12 2 -21 -4 -21
-6 0 -11 -4 -11 -10 0 -17 27 1 39 26 6 14 17 24 23 22 22 -8 19 -24 -8 -51
-15 -15 -22 -27 -17 -27 12 0 63 49 63 60 0 13 -28 32 -54 34 -18 2 -23 -1
-18 -12z"/>
<path d="M2014 176 c-85 -85 -100 -106 -52 -75 22 13 36 30 33 37 -3 7 0 10 5
7 9 -6 53 37 73 72 18 30 0 17 -59 -41z"/>
<path d="M117 195 c5 -38 63 -115 86 -115 5 0 -10 19 -32 43 -22 23 -41 51
-41 62 0 11 -5 27 -10 35 -6 10 -8 2 -3 -25z"/>
<path d="M300 155 c-81 -82 -84 -105 -3 -27 42 39 91 102 80 102 -1 0 -36 -34
-77 -75z"/>
<path d="M987 176 c-4 -10 -1 -16 7 -16 7 0 21 -9 30 -19 17 -19 46 -19 46 1
0 5 -5 6 -11 2 -6 -3 -23 6 -39 21 -23 22 -28 24 -33 11z"/>
<path d="M2642 148 c-16 -16 -15 -33 3 -48 18 -15 20 1 3 18 -9 9 -9 15 0 24
9 9 15 9 24 0 17 -17 33 -15 18 3 -15 18 -32 19 -48 3z"/>
</g>
</svg>



    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
